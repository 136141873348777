/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { SideBarNavMenuItemComponentProps } from './props';

import * as Styled from './styles';

const SideBarNavMenuItemComponent: React.FC<SideBarNavMenuItemComponentProps> =
  React.memo(props => {
    const { children, link, onClick, isRedirectToCourseLink } = props;
    const linkCourseCiaten = 'https://moodle.ciaten.org.br/';

    if (isRedirectToCourseLink) {
      return (
        <a href={linkCourseCiaten} target="_blank" rel="noreferrer">
          <Styled.ListItem onClick={onClick}>{children}</Styled.ListItem>
        </a>
      );
    }

    if (!link || typeof link !== 'string') {
      return <Styled.ListItem onClick={onClick}>{children}</Styled.ListItem>;
    }

    return (
      <Styled.ListItem
        component={React.forwardRef((props: NavLinkProps, ref: any) => (
          <NavLink exact {...props} innerRef={ref} />
        ))}
        to={link}
      >
        {children}
      </Styled.ListItem>
    );
  });

export { SideBarNavMenuItemComponent };
