/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import { User } from 'interface/researchersData';
import { ResearchersContextData } from './props';

const initialValue = {} as ResearchersContextData;

const ResearchersContext =
  React.createContext<ResearchersContextData>(initialValue);

const ResearchersProvider: React.FC = props => {
  const { children } = props;
  const [indexArrayUsers, setIndexArrayUsers] = React.useState(0);
  const [user, setUser] = React.useState<User[]>([]);
  const [userFind, setUserFind] = React.useState<User>({} as User);

  const addIndexArrayUsers = React.useCallback((indexNumber: number) => {
    setIndexArrayUsers(indexNumber);
  }, []);

  const getUser = React.useCallback((userData: User[]) => {
    setUser(userData);
  }, []);

  const getUserFind = React.useCallback(
    (userId: number) => {
      const userFindId = user.find(userIdFind => userIdFind.id === userId);
      setUserFind(userFindId as User);
    },
    [user],
  );

  return (
    <ResearchersContext.Provider
      value={{
        indexArrayUsers,
        addIndexArrayUsers,
        getUser,
        user,
        userFind,
        getUserFind,
      }}
    >
      {children}
    </ResearchersContext.Provider>
  );
};

export { ResearchersContext, ResearchersProvider, initialValue };
