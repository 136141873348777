import React from 'react';
import { useParams } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { Loading } from 'components/Loading';
import { RenderPDF } from 'components/RenderPDF';

import { NewsletterDataProps } from '../services/models/newsletters';
import { NewsLettersService } from '../services/newsletters.service';

import * as Styled from './styles';

type ParamsProps = {
  newsletterId: string;
};

const NewsletterDetails: React.FC = React.memo(() => {
  const [newsletter, newsletterData] = React.useState<NewsletterDataProps>();
  const [loading, setLoading] = React.useState(false);
  const { newsletterId } = useParams<ParamsProps>();

  React.useEffect(() => {
    setLoading(true);
    NewsLettersService.getNewsletter(newsletterId).then(({ data }) => {
      newsletterData(data);
      setLoading(false);
    });
  }, [newsletterId]);

  return (
    <Layout
      titleHeader="boletim do observatório epidemiológico"
      containerStyles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Styled.Container>
        <Loading isLoading={loading} color="secondary" />
        <Styled.Title variant="h2" className="citation_title">
          {newsletter?.title}
        </Styled.Title>

        <Styled.Authors>
          {newsletter?.authors_order.map((author, index) => (
            <Styled.AuthorName
              variant="h3"
              key={index}
              className="citation_author"
            >
              {author.author.name}
              {index !== newsletter.authors_order.length - 1 ? ',' : ''}
            </Styled.AuthorName>
          ))}
        </Styled.Authors>
        <Styled.Description variant="body1">
          {newsletter?.description.replace(/(<([^>]+)>)/g, '') || ''}
        </Styled.Description>

        {!loading && <RenderPDF src={newsletter?.file_path || ''} />}
      </Styled.Container>
    </Layout>
  );
});

export { NewsletterDetails };
