import React from 'react';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { FaDownload } from 'react-icons/fa';
import { formatDate } from 'utils/formateDate';
import { truncate } from 'utils/truncate';

import { RoutingPath } from 'utils/routing';
import * as Styled from './styles';
import { NewslettersCardProps } from './props';

const NewsletterCard: React.FC<NewslettersCardProps> = React.memo(props => {
  const { newslettersData, title } = props;
  const history = useHistory();

  const saveFile = (file_url: string) => {
    saveAs(file_url, file_url.split('/documents/')[1]);
  };

  const handleRedirectPageNewsletterDetails = (id: string) => {
    history.push(
      RoutingPath.getRouteString(
        RoutingPath.NEWSLETTERS_DETAILS,
        'newsletterId',
        id,
      ),
    );
  };

  return (
    <Styled.Grid container>
      <Styled.Header>
        <Styled.Title variant="h5">{title}</Styled.Title>
      </Styled.Header>

      <Styled.MainContainer>
        <Styled.GridContainer>
          {newslettersData?.map(item => (
            <Styled.CardContainer
              onClick={() =>
                handleRedirectPageNewsletterDetails(String(item.id))
              }
              key={item.id}
            >
              <Styled.Header>
                <Styled.NewsletterTitle
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: String(truncate(item.title || '', 70, 60)),
                  }}
                />
              </Styled.Header>

              <Styled.CardContent>
                <Styled.CitationInfo variant="body2">
                  v. {item.volume} n. {item.number} {item.year}
                </Styled.CitationInfo>
                <Styled.Thumbnail
                  component="img"
                  image={item.image_path}
                  alt={item.title}
                />
                <Styled.Description
                  color="tertiary"
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: String(truncate(item.description || '', 300, 60)),
                  }}
                />

                <Styled.DownloadButton
                  type="button"
                  color="primary"
                  variant="contained"
                  Icon={FaDownload}
                  onClick={() => saveFile(item.file_path)}
                >
                  Download
                </Styled.DownloadButton>
                <Styled.CreatedAtDate color="tertiary" variant="body1">
                  <Styled.FaClockIcon />
                  {formatDate(item.created)}
                </Styled.CreatedAtDate>
              </Styled.CardContent>
            </Styled.CardContainer>
          ))}
        </Styled.GridContainer>
      </Styled.MainContainer>
    </Styled.Grid>
  );
});

export { NewsletterCard };
