/* eslint-disable no-shadow */
import React from 'react';
import CiatenLogoMini from 'assets/images/ciaten-logo-mini.png';
import { SideBarNavMenu as SideBar } from './components/SideBar';
import { LayoutProps } from './props';

import * as Styled from './styles';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

const Layout: React.FC<LayoutProps> = React.memo(props => {
  const { children, titleHeader, containerStyles } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = React.useCallback(
    () => setDrawerOpen(drawerOpen => !drawerOpen),
    [],
  );

  const drawerElement = React.useMemo(
    () => (
      <Styled.ContainerDrawer>
        <Styled.Content>
          <Styled.ContentLogoAndTitle>
            <Styled.CiatenLogoMiniImage
              src={CiatenLogoMini}
              alt="Imgem Ciaten"
            />
            <Styled.Title>Ciaten</Styled.Title>
          </Styled.ContentLogoAndTitle>
          <Styled.Hr />
          <SideBar />
        </Styled.Content>
      </Styled.ContainerDrawer>
    ),
    [],
  );

  return (
    <Styled.Root>
      <Styled.Hidden mdUp implementation="css">
        <Styled.Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerElement}
        </Styled.Drawer>
      </Styled.Hidden>

      <Styled.Hidden mdDown implementation="css">
        <Styled.Drawer variant="permanent" anchor="left" open>
          {drawerElement}
        </Styled.Drawer>
      </Styled.Hidden>
      <Header titleHeader={titleHeader} toggleBar={handleDrawerToggle} />
      <Styled.Main maxWidth={false} style={containerStyles}>
        {children}
      </Styled.Main>
      <Footer />
    </Styled.Root>
  );
});

export { Layout };
