import React from 'react';
import { Layout } from 'components/Layout';

import * as Styled from './styles';

const Expedient: React.FC = React.memo(() => (
  <Layout
    titleHeader="boletim do observatório epidemiológico"
    containerStyles={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Styled.Container>
      <Styled.Card>
        <Styled.CardHeader>
          <Styled.CardTitle variant="h5">Expediente</Styled.CardTitle>
        </Styled.CardHeader>

        <Styled.CardContent>
          <Styled.ContentTitle variant="h3">
            Corpo Editorial
          </Styled.ContentTitle>

          <Styled.Ol>
            <Styled.Li>
              <Styled.LiTitle>Editor Geral</Styled.LiTitle>
              <Styled.Ol>
                <Styled.Li>Carlos Henrique Nery Costa</Styled.Li>
              </Styled.Ol>
            </Styled.Li>
            <Styled.Li>
              <Styled.LiTitle>Editores Executivos</Styled.LiTitle>
              <Styled.Ol>
                <Styled.Li>Bruno Guedes Alcoforado Aguiar</Styled.Li>
                <Styled.Li>Francisca Miriane de Araújo Batista</Styled.Li>
                <Styled.Li>segundo item terceiro subitem</Styled.Li>
              </Styled.Ol>
            </Styled.Li>
            <Styled.Li>
              <Styled.LiTitle>Comitê Editorial Científico</Styled.LiTitle>
              <Styled.Ol>
                <Styled.Li>Dorcas Lamounier Costa</Styled.Li>
                <Styled.Li>Fábio Solon Tajra</Styled.Li>
                <Styled.Li>Márcio Dênis Medeiros Mascarenhas</Styled.Li>
                <Styled.Li>Malvina Thais Pacheco Rodrigues</Styled.Li>
                <Styled.Li>Vagner José Mendonça</Styled.Li>
                <Styled.Li>Lilian Silva Catenacci</Styled.Li>
                <Styled.Li>Olivia Dias Araújo</Styled.Li>
              </Styled.Ol>
            </Styled.Li>
          </Styled.Ol>

          <Styled.Ul>
            <Styled.Li>Periodicidade: trimestral</Styled.Li>
            <Styled.Li>
              Autor: Centro de Inteligência em Agravos Tropicais Emergentes e
              Negligenciados
            </Styled.Li>
            <Styled.Li>
              Endereço: Rua Arthur de Vasconcelos, 151 - Centro - Teresina/PI -
              CEP: 64.001-450
            </Styled.Li>
            <Styled.Li>Editora responsável pela publicação: EDUFPI</Styled.Li>
          </Styled.Ul>
        </Styled.CardContent>
      </Styled.Card>
    </Styled.Container>
  </Layout>
));

export { Expedient };
