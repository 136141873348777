import { NavLinkProps } from 'react-router-dom';

import styled from 'styled-components';

import ListItemStyles from '@mui/material/ListItem';

interface ListItemProps {
  component?: React.ForwardRefExoticComponent<
    NavLinkProps<unknown> & React.RefAttributes<unknown>
  > &
    (React.ComponentType<any> | undefined);
  to?: string;
}

export const ListItem = styled(ListItemStyles)<ListItemProps>`
  margin-top: 4px;
  width: 200px;
  border-radius: 6px;
  cursor: pointer;

  .MuiListItemText-root {
    padding-left: 0;
  }

  .MuiTypography-root {
    color: #868e96;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 30px;
  }

  .MuiListItemIcon-root {
    color: ${({ theme }) => theme.palette.secondary.main};
    min-width: 30px;
  }

  &.active {
    background: ${({ theme }) => theme.palette.secondary.light};

    .MuiTypography-root {
      color: ${({ theme }) => theme.palette.secondary.main};
      font-weight: 700;
    }

    .MuiListItemIcon-root {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  :hover {
    background: ${({ theme }) => theme.palette.secondary.light};
  }
`;
