/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Layout } from 'components/Layout';
import { FaRegClock } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/formateDate';
import { Carousel } from 'components/Carousel';
import { Loading } from 'components/Loading';
import { NewsDetailsProps } from './services/models/news-details';
import { NewsDetailsService } from './services/news-details.service';

import * as Styled from './styles';

const NewsDetails: React.FC = React.memo(() => {
  const { newsId } = useParams<{ newsId?: string }>();
  const [newsDetailsData, setNewsDetailsData] =
    React.useState<NewsDetailsProps>({} as NewsDetailsProps);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    NewsDetailsService.getNewsDetails(Number(newsId))
      .then(({ data }) => {
        setNewsDetailsData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const imagesSlider: Array<{
    url: string;
    name: string;
  }> = newsDetailsData?.images?.map(image => ({
    url: image?.imagePath,
    name: image?.title,
  }));

  return (
    <Layout titleHeader="notícias sobre o ciaten">
      <Styled.Container>
        <Loading isLoading={loading} color="secondary" />
        <Styled.Title variant="h3">{newsDetailsData?.title}</Styled.Title>

        <Styled.ConatinerImages>
          <Styled.ContentImages>
            {imagesSlider !== undefined && (
              <Carousel imagesSlider={imagesSlider} />
            )}
          </Styled.ContentImages>
        </Styled.ConatinerImages>

        <Styled.ContentArticle>
          <Styled.ArticleText
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: String(newsDetailsData?.text || ''),
            }}
          />
        </Styled.ContentArticle>
        <Styled.Content>
          <Styled.AuthorText>
            Autor: {newsDetailsData?.author}
          </Styled.AuthorText>
          <FaRegClock size={12} color="#9a9a9a" />
          <Styled.DateText>
            {formatDate(newsDetailsData?.updated)}
          </Styled.DateText>
        </Styled.Content>
      </Styled.Container>
    </Layout>
  );
});

export { NewsDetails };
