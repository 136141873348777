import React from 'react';
import { useHistory } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { truncate } from 'utils/truncate';
import { CardProjectsProps } from './props';

import * as Styled from './styles';

const CardProjects: React.FC<CardProjectsProps> = React.memo(props => {
  const { projectsData } = props;

  const { push } = useHistory();

  const handleRedirectPageGroups = (groupId, userId) => {
    push(
      RoutingPath.getRouteString(
        RoutingPath.OTHER_GROUPS,
        ['groupsId', 'userId'],
        [`${groupId}`, `${userId}`],
      ),
    );
  };

  const handleRedirectPageProjectsDetails = (id: string) => {
    push(
      RoutingPath.getRouteString(
        RoutingPath.PROJECTS_DETAILS,
        'projectsId',
        id,
      ),
    );
  };

  return (
    <Styled.Grid container>
      {projectsData?.map(project => (
        <Styled.GridItem key={project?.id} item md={12} lg={6}>
          <Styled.Card>
            {project?.staff_ciaten ? (
              <Styled.CardHeaderWithImage>
                <Styled.TitleCardHeraderWithImage>
                  PROJETO: {project.title}
                </Styled.TitleCardHeraderWithImage>
                <Styled.ContentImages>
                  {project?.staff_ciaten.map(user => (
                    <Styled.HeaderImages
                      src={user?.profile?.imagePath}
                      alt="Imagem avatar"
                      onClick={() => {
                        handleRedirectPageGroups(user?.groups[0].id, user?.id);
                      }}
                    />
                  ))}
                </Styled.ContentImages>
              </Styled.CardHeaderWithImage>
            ) : (
              <Styled.CardHeader title={`Projeto: ${project?.title}`} />
            )}
            <Styled.CardMedia
              onClick={() =>
                handleRedirectPageProjectsDetails(String(project.id))
              }
              component="img"
              image={project?.images[0].thumbnail_512x288}
              alt="Imagem projeto"
            />

            <Styled.CardContent>
              <Styled.ArticleText
                variant="body1"
                color="tertiary"
                dangerouslySetInnerHTML={{
                  __html: String(
                    truncate(project?.description || '', 500, 297),
                  ),
                }}
              />
            </Styled.CardContent>
            <Styled.CardActions>
              <Styled.Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() =>
                  handleRedirectPageProjectsDetails(String(project.id))
                }
              >
                Mais
              </Styled.Button>
            </Styled.CardActions>

            <Styled.Content>
              <Styled.FinancialSourceText>
                <i>Fonte financeira:</i> {project?.financial_source}
              </Styled.FinancialSourceText>
            </Styled.Content>
          </Styled.Card>
        </Styled.GridItem>
      ))}
    </Styled.Grid>
  );
});

export { CardProjects };
