import React from 'react';
import { useResearchers } from 'hooks/researchers';
import { CardPresentationBiographyProps } from './props';

import * as Styled from './styles';

const CardPresentationBiography: React.FC<CardPresentationBiographyProps> =
  React.memo(props => {
    const { userMemberData, indexArrayUsers, userId } = props;

    const { user, getUserFind, userFind } = useResearchers();

    React.useEffect(() => {
      if (user) {
        getUserFind(Number(userId));
      }
    }, [user, userId]);

    return (
      <Styled.Card>
        {userFind ? (
          <Styled.CardHeader
            title={`${userFind?.first_name || ''} ${userFind?.last_name || ''}`}
          />
        ) : (
          <Styled.CardHeader
            title={`${
              userMemberData?.profile?.users[indexArrayUsers].first_name || ''
            } ${
              userMemberData?.profile?.users[indexArrayUsers].last_name || ''
            }`}
          />
        )}

        <Styled.CardContent>
          <Styled.DescripitionText variant="body1">
            {userFind
              ? userFind?.profile?.description_full
              : userMemberData?.profile?.users[indexArrayUsers].profile
                  .description_full}
          </Styled.DescripitionText>
        </Styled.CardContent>
      </Styled.Card>
    );
  });

export { CardPresentationBiography };
