import React from 'react';
import { Layout } from 'components/Layout';
import AboutImage from 'assets/images/about-img.svg';
import { CardNewsUfpi } from './components/CardNewsUfpi';
import { CardNews } from './components/CardNews';

import * as Styled from './styles';
import {
  NewsDataProps,
  NewsUfpiDataProps,
} from './components/services/models/home';
import { HomeService } from './components/services/home.service';

const Home: React.FC = React.memo(() => {
  const [newsData, setNewsData] = React.useState<NewsDataProps[]>();
  const [newsUfpiData, setNewsUfpiData] = React.useState<NewsUfpiDataProps[]>();

  React.useEffect(() => {
    HomeService.getNews().then(({ data }) => {
      setNewsData(data.results);
    });
  }, []);

  React.useEffect(() => {
    HomeService.getNewsUfpi().then(({ data }) => {
      setNewsUfpiData(data);
    });
  }, []);

  return (
    <Layout titleHeader="bem vindo ao ciaten">
      <Styled.Container>
        <Styled.Content>
          <Styled.AboutImage src={AboutImage} alt="Imagem about" />
          <Styled.DescritionCiatenText>
            O Centro de Inteligência em Agravos Tropicais Emergentes e
            Negligenciados (CIATEN) é uma organização partícipe do Instituto de
            Doenças do Sertão, com foco no aprimoramento do controle de doenças
            tropicais emergentes e negligenciadas, em especial no Estado do
            Piauí. O CIATEN se dedica a elaborar políticas públicas baseadas em
            evidências científicas, gerar e difundir conhecimentos científicos e
            tecnologias inovadoras, ensinar e treinar profissionais e produzir
            fármacos e dispositivos biotecnológicos. Este centro também
            estabelece parcerias e relações com entidades públicas e privadas
            para atingir seus objetivos. O CIATEN atua no estudo e combate a
            várias doenças e agravos, incluindo leishmaniose visceral (LV),
            dengue, chikungunya, zika, doença de Chagas, hanseníase, e acidentes
            de trânsito. Estes representam importantes desafios de saúde
            pública, impondo pesquisas e estratégias integradas para prevenção e
            tratamento.
          </Styled.DescritionCiatenText>
        </Styled.Content>

        <Styled.NewsText variant="h3" color="tertiary">
          Últimas notícias sobre o CIATEN
        </Styled.NewsText>

        <CardNews newsData={newsData} />

        <Styled.NewsText variant="h3" color="tertiary">
          Últimas notícias sobre a UFPI
        </Styled.NewsText>

        <CardNewsUfpi newsUfpiData={newsUfpiData} />
      </Styled.Container>
    </Layout>
  );
});

export { Home };
