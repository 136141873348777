import { RoutingPath } from 'utils/routing';
import {
  FaInfo,
  FaRegNewspaper,
  FaHome,
  FaFileAlt,
  FaConciergeBell,
  FaFlask,
  FaProjectDiagram,
  FaBook,
  FaGraduationCap,
} from 'react-icons/fa';
import { IconBaseProps } from 'react-icons';

interface MenuItemsProps {
  name: string;
  link?: string;
  Icon?: React.ComponentType<IconBaseProps>;
  itens?: MenuItemsProps[];
  isRedirectToCourseLink?: boolean;
}

export const menuItems: MenuItemsProps[] = [
  {
    name: 'home',
    link: RoutingPath.HOME,
    Icon: FaHome,
  },
  {
    name: 'sobre',
    link: RoutingPath.ABOUT,
    Icon: FaInfo,
  },
  {
    name: 'Boletins',
    link: RoutingPath.NEWSLETTERS,
    Icon: FaFileAlt,
  },
  {
    name: 'notícias',
    link: RoutingPath.NEWS,
    Icon: FaRegNewspaper,
  },
  {
    name: 'serviços',
    link: RoutingPath.SERVICES,
    Icon: FaConciergeBell,
  },
  {
    name: 'publicações',
    link: RoutingPath.PUBLICATIONS,
    Icon: FaBook,
  },
  {
    name: 'projetos',
    link: RoutingPath.PROJECTS,
    Icon: FaProjectDiagram,
  },
  {
    name: 'pesquisadores',
    Icon: FaFlask,
    itens: [
      {
        name: 'coordenação geral',
        link: RoutingPath.getRouteString(
          RoutingPath.RESEARCHERS,
          'groupsId',
          '13',
        ),
      },
      {
        name: 'núcleos temáticos',
        link: RoutingPath.getRouteString(
          RoutingPath.THEMATIC_CORE,
          'categoryId',
          '2',
        ),
      },
      {
        name: ' plataformas multidisciplinares',
        link: RoutingPath.getRouteString(
          RoutingPath.THEMATIC_CORE,
          'categoryId',
          '3',
        ),
      },
      {
        name: 'unidade de ensino',
        link: RoutingPath.getRouteString(
          RoutingPath.RESEARCHERS,
          'groupsId',
          '12',
        ),
      },
    ],
  },
  {
    name: 'cursos',
    Icon: FaGraduationCap,
    isRedirectToCourseLink: true,
  },
];
