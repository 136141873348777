import React from 'react';
import { ButtonProps } from './props';
import * as Styled from './styles';

const Button: React.FC<ButtonProps> = React.memo(props => {
  const { children, Icon, ...rest } = props;

  return (
    <Styled.ButtonContainer fullWidth type="button" {...rest} disableElevation>
      {children} {Icon && <Icon size={20} color="#ffffff" />}
    </Styled.ButtonContainer>
  );
});

export { Button };
