/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { LoadingProps } from './props';

import * as Styled from './styles';

const Loading: React.FC<LoadingProps> = React.memo(props => {
  const { color, isLoading, ...rest } = props;

  if (isLoading) {
    return (
      <Styled.Container>
        <Styled.CircularProgress color={color} {...rest} />
      </Styled.Container>
    );
  }

  return <></>;
});

export { Loading };
