import React from 'react';
import { GlobalStyles } from 'styles';
import { ThemeProvider as ThemeProviderStyledComponents } from 'styled-components';
import { ptBR } from 'date-fns/locale';
import { muiTheme } from 'styles/theme/mui-theme';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  ThemeProvider as ThemeMuiMaterial,
  CssBaseline,
  StyledEngineProvider,
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Routes } from './routes';

const App: React.FC = () => (
  <ThemeMuiMaterial theme={muiTheme}>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <StyledEngineProvider injectFirst>
        <ThemeProviderStyledComponents theme={muiTheme}>
          <GlobalStyles />
          <CssBaseline />
          <Routes />
        </ThemeProviderStyledComponents>
      </StyledEngineProvider>
    </LocalizationProvider>
  </ThemeMuiMaterial>
);

export { App };
