import React from 'react';
import { FaDownload, FaRegClock } from 'react-icons/fa';
import { formatDate } from 'utils/formateDate';
import { truncate } from 'utils/truncate';
import { useHistory } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { CardPublicationsProps } from './props';

import * as Styled from './styles';

const CardPublications: React.FC<CardPublicationsProps> = React.memo(props => {
  const { publicationsData } = props;
  const { push } = useHistory();

  const showPDF = (url: string) => window.open(url);

  const handleRedirectPageNewsletterDetails = (id: string) => {
    push(
      RoutingPath.getRouteString(
        RoutingPath.NEWSLETTERS_DETAILS,
        'newsletterId',
        id,
      ),
    );
  };

  return (
    <Styled.Grid container>
      {publicationsData?.map(publication => (
        <Styled.GridItem key={publication?.id} item sm={12} md={6} lg={4}>
          <Styled.Card>
            <Styled.CardHeader title={publication?.title || ''} />

            <Styled.CardContent>
              <Styled.Thumbnail
                component="img"
                image={publication?.thumbnail_256x362}
                alt="Imagem capa boletim"
                onClick={() =>
                  handleRedirectPageNewsletterDetails(String(publication?.id))
                }
              />
              <Styled.Description
                color="tertiary"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: String(
                    truncate(publication?.description || '', 300, 254),
                  ),
                }}
              />

              <Styled.DownloadButton
                type="button"
                color="primary"
                variant="contained"
                Icon={FaDownload}
                onClick={() => showPDF(publication?.file_path)}
              >
                Download
              </Styled.DownloadButton>
              <Styled.CreatedAtDate color="tertiary" variant="body1">
                <FaRegClock size={12} color="#9a9a9a" />
                {formatDate(publication?.created)}
              </Styled.CreatedAtDate>
            </Styled.CardContent>
          </Styled.Card>
        </Styled.GridItem>
      ))}
    </Styled.Grid>
  );
});

export { CardPublications };
