import React from 'react';
import { Layout } from 'components/Layout';
import { CardPresentationMemberThematicCoreOrMultidisciplinaryPlatforms } from 'components/CardsResearchers/CardPresentationMemberThematicCoreOrMultidisciplinaryPlatforms';
import { useParams } from 'react-router-dom';
import { ThematicCoreOrdMultidisciplinaryPlatformsDataProps } from './services/models/thematic-multidisciplinary';
import { ThematicCoreOrMultidisciplinaryPlatformsService } from './services/thematic-multidisciplinary.service';

import * as Styled from './styles';

const ThematicCoreOrMultidisciplinaryPlatforms: React.FC = React.memo(() => {
  const [thematicData, setThematicCore] =
    React.useState<ThematicCoreOrdMultidisciplinaryPlatformsDataProps>(
      {} as ThematicCoreOrdMultidisciplinaryPlatformsDataProps,
    );
  const { categoryId } = useParams<{ categoryId?: string }>();

  React.useEffect(() => {
    ThematicCoreOrMultidisciplinaryPlatformsService.getThematicCoreOrMultidisciplinaryPlatforms(
      Number(categoryId),
    ).then(({ data }) => {
      setThematicCore(data);
    });
  }, [categoryId]);

  return (
    <Layout titleHeader={thematicData.name}>
      <Styled.Container>
        {thematicData?.groups?.map(group => (
          <CardPresentationMemberThematicCoreOrMultidisciplinaryPlatforms
            name={group.name}
            admin={group.admin}
            groupId={group.id}
          />
        ))}
      </Styled.Container>
    </Layout>
  );
});

export { ThematicCoreOrMultidisciplinaryPlatforms };
