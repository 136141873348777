import { AxiosResponse } from 'axios';
import { ResearchersDataProps } from 'interface/researchersData';
import { api } from 'services/api';

const OverallCoordinationService = {
  getOverallCoordination: (
    groupsId: number,
  ): Promise<AxiosResponse<ResearchersDataProps>> =>
    api.get(`groups/${groupsId}/`),
};

export { OverallCoordinationService };
