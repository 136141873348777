export function truncate(
  text,
  textLenght: number,
  textLenghtSubstring: number,
): string {
  if (text?.length > textLenght) {
    return `${text
      .substring(0, textLenghtSubstring)
      .replace(
        '<p><img src="https://even3.blob.core.windows.net/pagina-evento/IMG-20201127-WA0068.0f5f047dcd424b12ba7f.jpg">\t</p>',
        '',
      )
      .replace(
        '<p><img src="https://even3.blob.core.windows.net/pagina-evento/IMG-20201126-WA0115.da0f873901394d13b6d3.jpg"></p>',
        '',
      )}...`;
  }

  return text;
}
