import React from 'react';
import { Layout } from 'components/Layout';
import { Pagination } from '@mui/material';
import { InputSearch } from 'components/Inputs/InputSearch';
import { Loading } from 'components/Loading';
import { CardPublications } from './components/CardPublications';
import { PublicatationsDataProps } from './services/models/publications';
import { PublicationsService } from './services/publications.service';

import * as Styled from './styles';

const LIMIT_PER_PAGE = 6;

const Publications: React.FC = React.memo(() => {
  const [publicationsData, setPublicationsData] =
    React.useState<PublicatationsDataProps[]>();
  const [count, setCount] = React.useState<number | null>(0);
  const [offset, setOffset] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (search) {
      setOffset(0);
    }
    setLoading(true);

    PublicationsService.getPublications(offset, search)
      .then(({ data }) => {
        setPublicationsData(data.results);
        setCount(Math.round(Number(data.count) / LIMIT_PER_PAGE));
      })
      .finally(() => setLoading(false));
  }, [offset, search]);

  const handleNextOrPreviousPage = (e, p) => {
    setOffset((p - 1) * LIMIT_PER_PAGE);
  };

  const showContent = () => {
    if (loading) {
      return <Loading isLoading={loading} color="secondary" />;
    }
    return <CardPublications publicationsData={publicationsData} />;
  };

  return (
    <Layout titleHeader="publicações do ciaten">
      <Styled.Container>
        <Pagination
          count={Number(count)}
          showFirstButton
          showLastButton
          onChange={handleNextOrPreviousPage}
          color="secondary"
        />
        <Styled.ContentInput>
          <InputSearch
            label=""
            name="search"
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Styled.ContentInput>
      </Styled.Container>
      {publicationsData?.length === 0 ? (
        <Styled.ContentPublicationsNotFound>
          <Styled.PublicationsNotFoundText variant="h3">
            Nenhum boletim encontrado
          </Styled.PublicationsNotFoundText>
        </Styled.ContentPublicationsNotFound>
      ) : (
        showContent()
      )}
    </Layout>
  );
});

export { Publications };
