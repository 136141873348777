import React from 'react';
import { useLocation } from 'react-router-dom';

import LogoUpfi from 'assets/images/ufpi-logo.png';
import LogoDiadorim from 'assets/images/diadorim.png';
import LogoFapepi from 'assets/images/fapepi-logo.png';

import * as Styled from './styles';

const Footer: React.FC = React.memo(() => {
  const today = new Date();
  const year = today.getFullYear();
  const { pathname } = useLocation();

  return (
    <Styled.Container maxWidth={false}>
      <Styled.Main>
        <Styled.TextYearCiaten variant="body2" color="tertiary">
          &copy; CIATEN {year}
        </Styled.TextYearCiaten>

        <Styled.Partners>
          <Styled.TextPartners variant="body2" color="tertiary">
            Parceiros:
          </Styled.TextPartners>
          <img className="ufpi" src={LogoUpfi} alt="Logo Ufpi" />
          <img className="fapepi" src={LogoFapepi} alt="Logo Fapepi" />
          {pathname === '/boletins' && (
            <img
              className="diadorimi"
              src={LogoDiadorim}
              alt="Logo Diadorimi"
            />
          )}
        </Styled.Partners>
      </Styled.Main>
    </Styled.Container>
  );
});

export { Footer };
