import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const ContentInput = styled.div`
  width: 350px;

  @media (max-width: 650px) {
    margin-top: 16px;
    width: 100%;
  }
`;

export const ContentNewsNotFound = styled.div`
  display: flex;
  justify-content: center;
`;

export const NewsNotFoundText = styled(TypographyStyles)`
  margin-top: 100px;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`;
