import styled from 'styled-components';
import { TextField as TextFieldStyles } from '@mui/material';
import { FormControl as FormControlStyles } from '@mui/material';

export const InputContainer = styled(TextFieldStyles)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};

  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 4px;
  }
`;

export const FormControlContainer = styled(FormControlStyles)`
  margin-bottom: 16px;
  .MuiOutlinedInput-notchedOutline {
    border-width: 1.5px !important;
  }
`;
