import React from 'react';
import { Layout } from 'components/Layout';
import { Loading } from 'components/Loading';
import { ServicesService } from './services/services.service';
import { ServicesDataProps } from './services/models/services';
import { CardServices } from './components/CardServices';

import * as Styled from './styles';

const Services: React.FC = React.memo(() => {
  const [servicesData, setServicesData] = React.useState<ServicesDataProps[]>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    ServicesService.getServices()
      .then(({ data }) => {
        setServicesData(data.results);
      })
      .finally(() => setLoading(false));
  }, []);

  const showContent = () => {
    if (loading) {
      return <Loading isLoading={loading} color="secondary" />;
    }
    return <CardServices servicesData={servicesData} />;
  };

  return (
    <Layout titleHeader="serviços prestados pelo ciaten">
      <Styled.Container>{showContent()}</Styled.Container>
    </Layout>
  );
});

export { Services };
