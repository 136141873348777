type RoutesParams =
  | 'newsId'
  | 'newsletterId'
  | 'groupsId'
  | 'userId'
  | 'categoryId'
  | 'projectsId';

class RoutingPath {
  static HOME = '/';

  static ABOUT = '/sobre';

  static NEWS = '/noticias';

  static NEWS_DETAILS = '/noticias/:newsId';

  static NEWSLETTERS = '/boletins';

  static NEWSLETTERS_EXPEDIENT = '/boletins/expediente';

  static NEWSLETTERS_PREVIOUS_VOLUMES = '/boletins/volumes-anteriores';

  static NEWSLETTERS_CURRENT_VOLUME = '/boletins/volume-atual';

  static NEWSLETTERS_PUBLISHING_STANDARDS = '/boletins/normas-de-publicacao';

  static NEWSLETTERS_DETAILS = '/boletins/:newsletterId';

  static SERVICES = '/servicos';

  static RESEARCHERS = '/grupos/:groupsId';

  static OTHER_GROUPS = '/grupos/:groupsId/:userId';

  static THEMATIC_CORE = '/grupos/categorias/:categoryId';

  static PROJECTS = '/projetos';

  static PROJECTS_DETAILS = '/projetos/:projectsId';

  static PUBLICATIONS = '/pulicacoes';

  static getRouteString(
    route: string,
    paramId: RoutesParams | RoutesParams[],
    value: string | string[],
  ) {
    if (Array.isArray(paramId)) {
      let endRoute = route;
      paramId.forEach((param, index) => {
        endRoute = endRoute.replace(`:${param}`, value[index]);
      });
      return endRoute;
    }
    if (Array.isArray(value)) value.join('');
    return route.replace(`:${paramId}`, value as string);
  }
}

export { RoutingPath };
