/* eslint-disable no-self-compare */
import React from 'react';
import { Layout } from 'components/Layout';
import AboutImage from 'assets/images/about-img.svg';
import { Loading } from 'components/Loading';
import { Purpose } from './components/Purpose';
import { OverallCoordination } from './components/OverallCoordination';
import { OverallCoordinationService } from './services/overall-coordination.service';
import { OverallCoordinationDataProps } from './services/models/overall-coordination';
import { InternalRegime } from './components/InternalRegime';

import * as Styled from './styles';
import { InternalRegimeDataProps } from './services/models/Internal-regime';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Styled.BoxTabPanel sx={{ p: 3 }}>
          <Styled.Typography>{children}</Styled.Typography>
        </Styled.BoxTabPanel>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const About: React.FC = React.memo(() => {
  const [value, setValue] = React.useState(0);
  const [resultData, setResultData] = React.useState<
    OverallCoordinationDataProps[]
  >([]);
  const [resultInternalRegimeData, setResultInternalRegimeData] =
    React.useState<InternalRegimeDataProps[]>([]);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setLoading(true);
    OverallCoordinationService.getOverallCoordination()
      .then(({ data: { results: result = [] } }) => {
        setResultData(result);
      })
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    OverallCoordinationService.getInternalRegime().then(
      ({ data: { results: result = [] } }) => {
        setResultInternalRegimeData(result);
      },
    );
  }, []);

  return (
    <Layout titleHeader="saiba mais sobre o ciaten">
      <Styled.Container>
        <Styled.ContentImageAndTexts>
          <Styled.AboutImage src={AboutImage} alt="Imagem about" />

          <Styled.ContentTexts>
            <Styled.AboutText variant="h3" color="tertiary">
              Sobre
            </Styled.AboutText>

            <Styled.InformativeText>
              O Centro de Inteligência em Agravos Tropicais Emergentes e
              Negligenciados (CIATEN) é uma organização partícipe do Instituto
              de Doenças do Sertão, com foco no aprimoramento do controle de
              doenças tropicais emergentes e negligenciadas, em especial no
              Estado do Piauí. O CIATEN se dedica a elaborar políticas públicas
              baseadas em evidências científicas, gerar e difundir conhecimentos
              científicos e tecnologias inovadoras, ensinar e treinar
              profissionais e produzir fármacos e dispositivos biotecnológicos.
              Este centro também estabelece parcerias e relações com entidades
              públicas e privadas para atingir seus objetivos. O CIATEN atua no
              estudo e combate a várias doenças e agravos, incluindo
              leishmaniose visceral (LV), dengue, chikungunya, zika, doença de
              Chagas, hanseníase, e acidentes de trânsito. Estes representam
              importantes desafios de saúde pública, impondo pesquisas e
              estratégias integradas para prevenção e tratamento.
            </Styled.InformativeText>
          </Styled.ContentTexts>
        </Styled.ContentImageAndTexts>

        <Styled.BoxContainer>
          <Styled.BoxContent>
            <Styled.Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Styled.Tab label="Coordenação Geral" {...a11yProps(0)} />
              <Styled.Tab label="Propósito" {...a11yProps(1)} />
              <Styled.Tab label="Regimento Interno" {...a11yProps(2)} />
            </Styled.Tabs>
          </Styled.BoxContent>
          <TabPanel value={value} index={0}>
            <Loading isLoading={loading} color="secondary" />
            <OverallCoordination resultData={resultData} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Purpose />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <InternalRegime
              resultInternalRegimeData={resultInternalRegimeData}
            />
          </TabPanel>
        </Styled.BoxContainer>
      </Styled.Container>
    </Layout>
  );
});

export { About };
