import React from 'react';
import { formatDate } from 'utils/formateDate';
import { CardNewsUfpiProps } from './props';

import * as Styled from './styles';

const CardNewsUfpi: React.FC<CardNewsUfpiProps> = React.memo(props => {
  const { newsUfpiData } = props;

  const handleRedirectNewsUfpi = (news: string) => {
    window.open(news);
  };

  return (
    <Styled.Container>
      <Styled.Content>
        {newsUfpiData?.map(news => (
          <Styled.Card key={news?.key}>
            <Styled.CardHeader title={news?.key} />
            <Styled.CardContent>
              <Styled.TitleNews variant="body1">{news?.title}</Styled.TitleNews>

              <Styled.Footer>
                <Styled.ButtonNews
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleRedirectNewsUfpi(news?.link);
                  }}
                >
                  +
                </Styled.ButtonNews>

                <Styled.CreatedAtDate>
                  {formatDate(new Date(news?.published))}
                </Styled.CreatedAtDate>
              </Styled.Footer>
            </Styled.CardContent>
          </Styled.Card>
        ))}
      </Styled.Content>
    </Styled.Container>
  );
});

export { CardNewsUfpi };
