import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';
import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1108px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const PresentationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const RightContent = styled.div`
  margin: 16px 0 0 56px;
  align-items: flex-start;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const AboutText = styled(TypographyStyles)`
  margin: 24px 0;
`;

export const PeriodicPresentation = styled.p`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: justify;

  + p {
    margin-top: 24px;
  }
`;

export const AboutImage = styled.img`
  height: 35%;
  width: 35%;

  @media (max-width: 900px) {
    width: 70%;
    height: 70%;
  }
`;

export const ButtonsGroups = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  align-items: center;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ActionButton = styled(Button)`
  font-size: 12px;
`;
