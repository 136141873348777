import React from 'react';
import { useResearchers } from 'hooks/researchers';
import { useHistory } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { CardPresentationWorkProps } from './props';

import * as Styled from './styles';

const CardPresentationWork: React.FC<CardPresentationWorkProps> = React.memo(
  props => {
    const {
      description,
      financial_source,
      staff_ciaten,
      title,
      userMemberData,
    } = props;
    const { push } = useHistory();
    const { addIndexArrayUsers } = useResearchers();

    const handleRedirectPageOthersGroups = (groupsId, userId, user) => {
      const findfUserId = userMemberData?.profile?.users.findIndex(
        userFind => userFind.id === user.id,
      );

      addIndexArrayUsers(Number(findfUserId !== -1 && findfUserId));

      push(
        RoutingPath.getRouteString(
          RoutingPath.OTHER_GROUPS,
          ['groupsId', 'userId'],
          [`${groupsId}`, `${userId}`],
        ),
      );
    };

    return (
      <Styled.Card>
        <Styled.CardHeaderWithImage>
          <Styled.TitleCardHeraderWithImage>
            PROJETO: {title}
          </Styled.TitleCardHeraderWithImage>
          <Styled.ContentImages>
            {staff_ciaten.map(user => (
              <Styled.HeaderImages
                onClick={() =>
                  handleRedirectPageOthersGroups(
                    user?.groups[0].id,
                    user?.id,
                    user,
                  )
                }
                src={user?.profile?.imagePath}
                alt="Imagem avatar"
              />
            ))}
          </Styled.ContentImages>
        </Styled.CardHeaderWithImage>

        <Styled.CardContent>
          <Styled.DescripitionText
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: description || '',
            }}
          />

          <Styled.FinancialSourceText variant="body2">
            <i>Fonte financeira</i>: {financial_source}
          </Styled.FinancialSourceText>
        </Styled.CardContent>
      </Styled.Card>
    );
  },
);

export { CardPresentationWork };
