/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { CardServicesProps } from './props';

import * as Styled from './styles';

const CardServices: React.FC<CardServicesProps> = React.memo(props => {
  const { servicesData } = props;

  return (
    <React.Fragment>
      {servicesData?.map(item => (
        <Styled.Content>
          <Styled.Image src={item.thumbnail_512x288} alt="Imagem" />

          <Styled.ContentTexts>
            <Styled.Title variant="h3" color="tertiary">
              {item.title}
            </Styled.Title>

            <Styled.DescriptionText variant="body1" color="tertiary">
              {item.description}
            </Styled.DescriptionText>
          </Styled.ContentTexts>
        </Styled.Content>
      ))}
    </React.Fragment>
  );
});

export { CardServices };
