import { AxiosResponse } from 'axios';
import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { PublicatationsDataProps } from './models/publications';

const PublicationsService = {
  getPublications: (
    offset: number,
    search?: string,
  ): Promise<AxiosResponse<ResponseData<PublicatationsDataProps[]>>> =>
    api.get(`website/documents/`, { params: { limit: 6, offset, search } }),
};

export { PublicationsService };
