import styled from 'styled-components';
import CardStyles from '@mui/material/Card';
import CardHeaderStyles from '@mui/material/CardHeader';
import CardContentStyles from '@mui/material/CardContent';
import TypographyStyles from '@mui/material/Typography';
import AvatarStyles from '@mui/material/Avatar';
import DividerStyles from '@mui/material/Divider';

export const Card = styled(CardStyles)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const AvatarBorder = styled.div`
  padding: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid #dee2e6;
  border-radius: 50%;
`;

export const Avatar = styled(AvatarStyles)`
  width: 150px;
  height: 150px;
`;

export const CardHeader = styled(CardHeaderStyles)`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 8px;
`;

export const CardContent = styled(CardContentStyles)`
  padding: 20px;
`;

export const Title = styled(TypographyStyles)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  text-align: center;
  margin-bottom: 8px;
`;

export const EmailText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.grey[200]};
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
`;

export const DescriptionText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  text-align: center;
`;

export const Divider = styled(DividerStyles)`
  margin: 16px 0;
`;

export const Lattesext = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary[100]};
  text-align: center;
`;
