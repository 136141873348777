import { AxiosResponse } from 'axios';
import { api } from 'services/api';
import { NewsDetailsProps } from './models/news-details';

const NewsDetailsService = {
  getNewsDetails: (newsId: number): Promise<AxiosResponse<NewsDetailsProps>> =>
    api.get(`website/news/${newsId}/`),
};

export { NewsDetailsService };
