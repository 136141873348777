import { Layout } from 'components/Layout';
import React from 'react';

import * as Styled from './styles';

const PublishingStandards: React.FC = React.memo(() => (
  <Layout
    titleHeader="boletim do observatório epidemiológico"
    containerStyles={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Styled.Container>
      <Styled.Card>
        <Styled.CardHeader>
          <Styled.CardTitle variant="h5">
            normas de publicações
          </Styled.CardTitle>
        </Styled.CardHeader>

        <Styled.CardContent>
          <Styled.ContentDescription variant="body1">
            O Boletim do Observatório Epidemiológico (BOE) será elaborado pelo
            Centro de Inteligência em Agravos Tropicais, Emergentes e
            Negligenciados (CIATEN) por meio das plataformas e núcleos temáticos
            que constitui as áreas responsáveis em desenvolver políticas
            públicas baseadas em evidências cientificas seguindo as seguintes
            especificações:
          </Styled.ContentDescription>

          <Styled.Ol>
            <Styled.Li>
              <Styled.LiTitle>Idioma</Styled.LiTitle>
              <Styled.Ol>O boletim deve ser escrito em português.</Styled.Ol>
            </Styled.Li>

            <Styled.Li>
              <Styled.LiTitle>
                Número de páginas e tamanho da letra
              </Styled.LiTitle>
              <Styled.Ol>
                Os manuscritos devem ter no máximo 15 páginas, incluindo
                referências e anexos ou apêndices; Papel tamanho A4 (21 cm X
                29,7 cm), com margens superior e esquerda de 3 cm e direita e
                inferior de 2 cm. A fonte deverá ser Times New Romam ou Arial,
                tamanho 12, espaçamento 1,5, parágrafo justificado com recuo de
                1,5 cm.
              </Styled.Ol>
            </Styled.Li>

            <Styled.Li>
              <Styled.LiTitle>Estrutura</Styled.LiTitle>
              <Styled.Ol>
                Título do artigo: em maiúsculas e em negrito, centralizado no
                alto da primeira página, em espaçamento simples entrelinhas,
                tamanho 12.
              </Styled.Ol>
              <Styled.Ol>
                Nome dos autores: por extenso, duas linhas abaixo do título,
                alinhado à direita;
              </Styled.Ol>
              <Styled.Ol>
                Corpo do texto: de constar apresentação, método, resultados,
                considerações finais e os encaminhamentos para prática. O texto
                deve iniciar duas linhas abaixo, entrelinhas 1,5, fonte tamanho
                12, justificado, adentramento de 1,5. Subtítulos devem ser
                alinhados à margem esquerda;
              </Styled.Ol>
              <Styled.Ol>
                Referências: para citação das referências no texto, deve-se
                obedecer às normas da ABNT nominal e disposição bibliográfica em
                ordem alfabética.
              </Styled.Ol>
            </Styled.Li>

            <Styled.Li>
              <Styled.LiTitle>EDIÇÃO ATUAL</Styled.LiTitle>
              <Styled.Ol>
                MORTES POR LESÕES DE TRÂNSITO – PIAUÍ, 2008 A 2018
              </Styled.Ol>
              <Styled.Ol>
                CARACTERIZAÇÃO DOS CASOS DE LEISHMANIONSE VISCERAL – PIAUÍ, 2008
                A 2018
              </Styled.Ol>
            </Styled.Li>
          </Styled.Ol>
        </Styled.CardContent>
      </Styled.Card>
    </Styled.Container>
  </Layout>
));

export { PublishingStandards };
