import React from 'react';
import { FiMenu, FiArrowLeft } from 'react-icons/fi';
import { ButtonBase } from '@mui/material';

import { Link } from 'react-router-dom';
import { HeaderProps } from './props';
import * as Styled from './styles';

const Header: React.FC<HeaderProps> = React.memo(props => {
  const { toggleBar, titleHeader } = props;

  return (
    <Styled.Container maxWidth={false}>
      <Styled.Main>
        <Styled.TitleHeader variant="body2">{titleHeader}</Styled.TitleHeader>
        <Styled.ContentButtonMenuMobile>
          <ButtonBase onClick={toggleBar}>
            <FiMenu color="#66615b" size={20} />
          </ButtonBase>
        </Styled.ContentButtonMenuMobile>

        <Styled.ContentButtonLogin>
          <a
            href="http://admin.ciaten.org.br/"
            target="_blank"
            rel="noreferrer"
          >
            <Styled.ButtonLogin>
              <FiArrowLeft color="#66615b" size={20} /> Login
            </Styled.ButtonLogin>
          </a>
        </Styled.ContentButtonLogin>
      </Styled.Main>
    </Styled.Container>
  );
});

export { Header };
