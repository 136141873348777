import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const AboutImage = styled.img`
  height: 30%;
  width: 30%;

  @media (max-width: 900px) {
    width: 70%;
    height: 70%;
  }
`;

export const DescritionCiatenText = styled.p`
  margin: 56px 0 0 100px;
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: justify;

  @media (max-width: 900px) {
    margin: 16px 0;
  }
`;

export const NewsText = styled(TypographyStyles)`
  margin: 24px 0;
`;
