import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import { formatDate } from 'utils/formateDate';
import { truncate } from 'utils/truncate';
import { useHistory } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { CardNewsProps } from './props';

import * as Styled from './styles';

const CardNews: React.FC<CardNewsProps> = React.memo(props => {
  const { newsData } = props;
  const history = useHistory();

  const handleRedirectPageNewsDetails = (id: string) => {
    history.push(
      RoutingPath.getRouteString(RoutingPath.NEWS_DETAILS, 'newsId', id),
    );
  };

  return (
    <Styled.Grid container>
      {newsData?.map(item => (
        <Styled.GridItem key={item.id} item md={12} lg={6}>
          <Styled.Card>
            <Styled.CardHeader title={item.title} />

            <Styled.CardMedia
              component="img"
              image={item.images[0].thumbnail_512x288}
              alt="Imagem pdf"
              onClick={() => handleRedirectPageNewsDetails(String(item.id))}
            />
            <Styled.CardContent>
              <Styled.ArticleText
                color="tertiary"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: String(truncate(item?.text || '', 300, 254)),
                }}
              />
            </Styled.CardContent>
            <Styled.CardActions>
              <Styled.Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => handleRedirectPageNewsDetails(String(item.id))}
              >
                Mais
              </Styled.Button>
            </Styled.CardActions>

            <Styled.Content>
              <Styled.AuthorText>Autor: {item.author}</Styled.AuthorText>
              <FaRegClock size={12} color="#9a9a9a" />
              <Styled.DateText>{formatDate(item.updated)}</Styled.DateText>
            </Styled.Content>
          </Styled.Card>
        </Styled.GridItem>
      ))}
    </Styled.Grid>
  );
});

export { CardNews };
