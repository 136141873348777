import styled from 'styled-components';

import ContainerStyles from '@mui/material/Container';
import DrawerStyles from '@mui/material/Drawer';
import HiddenStyles from '@mui/material/Hidden';
import TypographyStyles from '@mui/material/Typography';

export const Root = styled.div`
  margin: auto;
`;

export const Drawer = styled(DrawerStyles)`
  .MuiDrawer-paper {
    width: 250px;
    max-height: 100vh;
    align-items: center;
    overflow-y: auto;
  }
`;

export const ContainerDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: ${({ theme }) => theme.palette.background.default};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentLogoAndTitle = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: 29px;
  line-height: 35px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const CiatenLogoMiniImage = styled.img`
  height: 60px;
  width: 60px;
`;

export const Hr = styled.hr`
  border: none;
  display: initial;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  margin-top: 5px;
`;

export const Main = styled(ContainerStyles)`
  position: relative;
  padding: 24px 100px 0 350px;
  min-height: calc(100vh - 200px);
  margin: auto;
  margin-bottom: 18px;

  @media (max-width: 900px) {
    padding: 16px;
  }
`;

export const Hidden = styled(HiddenStyles)``;
