import React from 'react';
import { SideBarNavMenuItem } from '../SideBarNavMenuItem';
import { menuItems } from './menuItems';

import * as Styled from './styles';

const SideBarNavMenu: React.FC = React.memo(() => (
  <Styled.ListContainer component="nav" disablePadding>
    {menuItems.map((item, index) => (
      <SideBarNavMenuItem {...item} key={index} />
    ))}
  </Styled.ListContainer>
));

export { SideBarNavMenu };
