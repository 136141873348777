import styled from 'styled-components';
import CircularProgressStyles from '@mui/material/CircularProgress';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
`;

export const CircularProgress = styled(CircularProgressStyles)`
  &.MuiCircularProgress-colorSecondary {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
