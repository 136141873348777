import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';

const muiTheme = createTheme(
  {
    typography: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
      // 21px
      h3: {
        fontSize: '1.3125rem',
        lineHeight: '25px',
        fontWeight: 400,
      },
      // 19px
      h4: {
        fontSize: '1.1875rem',
        lineHeight: '29px',
        fontWeight: 500,
      },
      // 13px
      h5: {
        fontSize: '0.8125rem',
        lineHeight: '16px',
        fontWeight: 700,
      },
      // 14px
      body1: {
        fontSize: '0.875rem',
        lineHeight: '20px',
        fontweight: 400,
      },
      // 16px
      body2: {
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 400,
      },
      button: {
        fontSize: '1rem',
        lineHeight: '28px',
        color: '#ffffff',
        fontWeight: '700',
      },
    },
    palette: {
      primary: {
        main: '#51cbce',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#f5593d',
        light: 'rgba(245,89,61,0.1)',
      },
      tertiary: {
        main: '#66615b',
        light: 'rgba(255, 255, 255, 0.7)',
        dark: '#252422',
        100: '#333333',
      },
      info: {
        main: '#51bcda',
      },
      warning: {
        main: '#fbc658',
      },
      success: {
        main: '#6bd098',
      },
      error: {
        main: '#dc3545',
      },
      background: {
        default: '#ffffff',
      },
      grey: {
        100: '#403d39',
        200: '#9a9a9a',
      },
    },
  },
  ptBR,
);

export { muiTheme };
