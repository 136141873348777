import styled from 'styled-components';

import TypographyStyles from '@mui/material/Typography';
import CardMediaStyles, { CardMediaProps } from '@mui/material/CardMedia';
import GridStyles from '@mui/material/Grid';
import { Button } from 'components/Button';
import CardStyles from '@mui/material/Card';
import CardHeaderStyles from '@mui/material/CardHeader';

type CardMediaStylesProps = CardMediaProps & {
  component?: string;
  height?: string;
  alt?: string;
};

export const Card = styled(CardStyles)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
`;

export const CardContent = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardHeader = styled(CardHeaderStyles)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .MuiTypography-root {
    font-size: 19px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    font-weight: 500;
    line-height: 29px;
  }
`;

export const Description = styled(TypographyStyles)`
  text-align: justify;
  margin: 24px 0;
  color: ${({ theme }) => theme.palette.tertiary.main};

  span {
    color: ${({ theme }) => theme.palette.tertiary.main} !important;
  }
`;

export const Thumbnail = styled(CardMediaStyles)<CardMediaStylesProps>`
  border-radius: 12px 12px 0 0;
  cursor: pointer;
`;

export const DownloadButton = styled(Button)``;

export const CreatedAtDate = styled(TypographyStyles)`
  margin-top: 12px;
  font-size: 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[200]};

  svg {
    margin-right: 2px;
  }
`;

export const Grid = styled(GridStyles)`
  margin: 0;

  .MuiGrid-root {
    width: 100%;
  }
`;

export const GridItem = styled(GridStyles)`
  padding: 8px;
`;
