/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Layout } from 'components/Layout';
import { useParams } from 'react-router-dom';
import { withContext } from 'utils/withContext';
import { CardPresentationGroup } from 'components/CardsResearchers/CardPresentationGroup';
import { CardPresentationMember } from 'components/CardsResearchers/CardPresentationMember';
import { CardPresentationWork } from 'components/CardsResearchers/CardPresentationWork';
import { CardListMembers } from 'components/CardsResearchers/CardListMembers';
import { ResearchersDataProps } from 'interface/researchersData';
import { useResearchers, ResearchersProvider } from 'hooks/researchers';
import { CardPresentationBiography } from 'components/CardsResearchers/CardPresentationBiography';
import { OtherGroupsService } from './services/other-groups.service';

import * as Styled from './styles';

export const OtherGroups: React.FC = withContext(
  React.memo(() => {
    const { indexArrayUsers, getUser } = useResearchers();
    const [otherGroupsData, setOtherGroupsData] =
      React.useState<ResearchersDataProps>({} as ResearchersDataProps);
    const { groupsId } = useParams<{ groupsId?: string }>();
    const { userId } = useParams<{ userId?: string }>();

    React.useEffect(() => {
      OtherGroupsService.getOtherGroups(Number(groupsId)).then(({ data }) => {
        setOtherGroupsData(data);
        getUser(data.profile.users);
      });
    }, [groupsId, userId]);

    return (
      <Layout titleHeader={otherGroupsData?.profile?.name}>
        <Styled.Container>
          <Styled.ContentCardLeft>
            <CardPresentationMember
              indexArrayUsers={indexArrayUsers}
              userMemberData={otherGroupsData}
              userId={userId}
            />
            <CardListMembers userMemberData={otherGroupsData} />
          </Styled.ContentCardLeft>
          <Styled.ContentCardRight>
            <CardPresentationGroup userMemberData={otherGroupsData} />
            <CardPresentationBiography
              indexArrayUsers={indexArrayUsers}
              userMemberData={otherGroupsData}
              userId={userId}
            />

            {otherGroupsData?.profile?.users[indexArrayUsers].projects.map(
              project => (
                <CardPresentationWork
                  title={project.title}
                  userMemberData={otherGroupsData}
                  description={project.description}
                  financial_source={project.financial_source}
                  staff_ciaten={project.staff_ciaten}
                />
              ),
            )}
          </Styled.ContentCardRight>
        </Styled.Container>
      </Layout>
    );
  }),
  ResearchersProvider,
);
