import React from 'react';
import * as Styled from './styles';

const Purpose: React.FC = React.memo(() => (
  <Styled.Container>
    <Styled.Title variant="body1" color="tertiary">
      O CIATEN tem como finalidade:
    </Styled.Title>
    <Styled.InformativeText variant="body1" color="tertiary">
      Aprimorar o controle dos agravos tropicais emergentes e negligenciadas no
      contexto do Estado do Piauí e em regiões similares, devendo em especial:
    </Styled.InformativeText>

    <Styled.Ol>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Elaborar políticas públicas baseadas em evidências científicas;
        </Styled.Text>
      </Styled.Li>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Gerar e difundir conhecimentos científicos e tecnologias inovadoras;
        </Styled.Text>
      </Styled.Li>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Ensinar e treinar profissionais da administração pública e demais
          interessados;
        </Styled.Text>
      </Styled.Li>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Produzir fármacos e dispositivos biotecnológicos.
        </Styled.Text>
      </Styled.Li>
    </Styled.Ol>

    <Styled.Title variant="body1" color="tertiary">
      O CIATEN tem como missão:
    </Styled.Title>

    <Styled.Ol>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Aprimorar o controle dos agravos tropicais emergentes e negligenciadas
          (ATEN), especialmente no contexto do Estado do Piauí;
        </Styled.Text>
      </Styled.Li>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Orientar políticas públicas em respostas aos agravos comuns aos
          trópicos, negligenciados, vetoriais ou não, baseadas em evidências
          científicas;
        </Styled.Text>
      </Styled.Li>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Assegurar a formação científica de estudantes, profissionais e
          pesquisadores em medicina tropical, doenças negligenciadas e agravos
          emergentes de causa externa.
        </Styled.Text>
      </Styled.Li>
    </Styled.Ol>

    <Styled.Title variant="body1" color="tertiary">
      O CIATEN tem como visão:
    </Styled.Title>

    <Styled.Ol>
      <Styled.Li>
        <Styled.Text variant="body1" color="tertiary">
          Ser um centro de referência nacional em pesquisa e resposta aos
          agravos tropicais a fim de aumentar a eficiência das ações públicas de
          combate, controle e prevenção.
        </Styled.Text>
      </Styled.Li>
    </Styled.Ol>
  </Styled.Container>
));

export { Purpose };
