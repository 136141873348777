import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { About } from 'pages/About';
import { Home } from 'pages/Home';
import { News } from 'pages/NewsPages/News';
import { NewsDetails } from 'pages/NewsPages/NewsDetails';
import { Newsletters } from 'pages/NewsletterPages/Newsletters';
import { Expedient } from 'pages/NewsletterPages/Expedient';
import { OldNewsletters } from 'pages/NewsletterPages/OldNewsletters';
import { CurrentNewsletters } from 'pages/NewsletterPages/CurrentNewsletters';
import { PublishingStandards } from 'pages/NewsletterPages/PublishingStandards';
import { NewsletterDetails } from 'pages/NewsletterPages/NewsletterDetails';
import { Services } from 'pages/Services';
import { OverallCoordinationOrTeachingUnit } from 'pages/Researchers/OverallCoordinationOrTeachingUnit';
import { OtherGroups } from 'pages/Researchers/OtherGroups/OtherGroups';
import { ThematicCoreOrMultidisciplinaryPlatforms } from 'pages/Researchers/ThematicCoreOrMultidisciplinaryPlatforms';
import { Projects } from 'pages/ProjectsPage/Projects';
import { ProjectsDetails } from 'pages/ProjectsPage/ProjectsDetails';
import { Publications } from 'pages/Publications';

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path={RoutingPath.ABOUT} component={About} exact />
      <Route path={RoutingPath.HOME} component={Home} exact />
      <Route path={RoutingPath.NEWS} component={News} exact />
      <Route path={RoutingPath.NEWS_DETAILS} component={NewsDetails} exact />

      {/* Starting routing path for NEWSLETTERS */}
      <Route path={RoutingPath.NEWSLETTERS} component={Newsletters} exact />
      <Route
        path={RoutingPath.NEWSLETTERS_EXPEDIENT}
        component={Expedient}
        exact
      />
      <Route
        path={RoutingPath.NEWSLETTERS_PREVIOUS_VOLUMES}
        component={OldNewsletters}
        exact
      />
      <Route
        path={RoutingPath.NEWSLETTERS_CURRENT_VOLUME}
        component={CurrentNewsletters}
        exact
      />
      <Route
        path={RoutingPath.NEWSLETTERS_PUBLISHING_STANDARDS}
        component={PublishingStandards}
        exact
      />
      <Route
        path={RoutingPath.NEWSLETTERS_DETAILS}
        component={NewsletterDetails}
        exact
      />
      {/* End routing path for NEWSLETTERS */}
      <Route path={RoutingPath.SERVICES} component={Services} exact />
      <Route
        path={RoutingPath.RESEARCHERS}
        component={OverallCoordinationOrTeachingUnit}
        exact
      />
      <Route
        path={RoutingPath.THEMATIC_CORE}
        component={ThematicCoreOrMultidisciplinaryPlatforms}
        exact
      />
      <Route path={RoutingPath.OTHER_GROUPS} component={OtherGroups} exact />
      <Route path={RoutingPath.PROJECTS} component={Projects} exact />
      <Route
        path={RoutingPath.PROJECTS_DETAILS}
        component={ProjectsDetails}
        exact
      />
      <Route path={RoutingPath.PUBLICATIONS} component={Publications} exact />
    </Switch>
  </BrowserRouter>
);

export { Routes };
