import TypographyStyles from '@mui/material/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1108px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Title = styled(TypographyStyles)`
  font-size: 24pt;
  text-align: center;
`;

export const Authors = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const AuthorName = styled(TypographyStyles)`
  font-size: 18pt;
  text-align: center;

  + h3 {
    margin-top: 8px;
  }
`;

export const Description = styled(TypographyStyles)`
  max-width: 750px;
  text-align: justify;
  color: ${({ theme }) => theme.palette.tertiary.main};
`;
