import styled from 'styled-components';
import GridStyles from '@mui/material/Grid';

export const Container = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
`;

export const Grid = styled(GridStyles)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiGrid-root {
    width: 100%;
  }
`;

export const GridItem = styled(GridStyles)`
  padding: 8px;
`;
