import { AxiosResponse } from 'axios';
import { ResearchersDataProps } from 'interface/researchersData';
import { api } from 'services/api';

const OtherGroupsService = {
  getOtherGroups: (
    groupsId: number,
  ): Promise<AxiosResponse<ResearchersDataProps>> =>
    api.get(`groups/${groupsId}/`),
};

export { OtherGroupsService };
