import styled, { css } from 'styled-components';

type PDFToolbarProps = {
  currentTheme: string;
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const PDFViewer = styled.div`
  width: 230mm;
  height: 297mm;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 900px) {
    width: 100%;
    height: 140mm;
  }
`;

export const PDFRenderContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const PDFToolbar = styled.div<PDFToolbarProps>`
  display: flex;
  align-items: center;
  width: 230mm;
  padding: 0.25rem;

  @media (max-width: 900px) {
    width: 100%;
  }

  ${({ currentTheme }) =>
    currentTheme === 'dark'
      ? css`
          background-color: #292929;
          border-bottom: 1px solid #000000;
        `
      : css`
          background-color: #eeeeee;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        `}
`;
