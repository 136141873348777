import React from 'react';
import { SideBarNavMenuItemComponent } from '../SideBarNavMenuItemComponent';
import { SideBarNavMenuItemProps } from './props';

import * as Styled from './styles';

const SideBarNavMenuItem: React.FC<SideBarNavMenuItemProps> = React.memo(
  props => {
    const { link, name, Icon, itens, isRedirectToCourseLink } = props;

    const isExpandable = itens && itens.length > 0;

    const [open, setOpen] = React.useState(true);

    const handleOpenMenuItem = React.useCallback(() => {
      setOpen(!open);
    }, [open]);

    const MenuItemRoot = (
      <SideBarNavMenuItemComponent
        link={link}
        onClick={handleOpenMenuItem}
        isRedirectToCourseLink={isRedirectToCourseLink}
      >
        {!!Icon && (
          <Styled.ListItemIcon>
            <Icon size={20} />
          </Styled.ListItemIcon>
        )}
        <Styled.ListItemText primary={name} inset={!Icon} />
        {isExpandable && !open && <Styled.IconExpandMore />}
        {isExpandable && open && <Styled.IconExpandLess />}
      </SideBarNavMenuItemComponent>
    );

    const MenuItemChildren = isExpandable ? (
      <Styled.Collapse in={open} timeout="auto" unmountOnExit>
        <Styled.Divider />

        <Styled.List component="div" disablePadding>
          {itens?.map((item, index) => (
            <SideBarNavMenuItem {...item} key={index} />
          ))}
        </Styled.List>
      </Styled.Collapse>
    ) : null;

    return (
      <React.Fragment>
        {MenuItemRoot}
        {MenuItemChildren}
      </React.Fragment>
    );
  },
);

export { SideBarNavMenuItem };
