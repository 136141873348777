import styled from 'styled-components';
import ListStyles from '@mui/material/List';

export interface ListComponent {
  component?: string;
}

export const ListContainer = styled(ListStyles)<ListComponent>`
  width: 100%;
`;
