import { AxiosResponse } from 'axios';
import { api } from 'services/api';
import { ThematicCoreOrdMultidisciplinaryPlatformsDataProps } from './models/thematic-multidisciplinary';

const ThematicCoreOrMultidisciplinaryPlatformsService = {
  getThematicCoreOrMultidisciplinaryPlatforms: (
    categoryId: number,
  ): Promise<
    AxiosResponse<ThematicCoreOrdMultidisciplinaryPlatformsDataProps>
  > => api.get(`categorys-groups/${categoryId}/`),
};

export { ThematicCoreOrMultidisciplinaryPlatformsService };
