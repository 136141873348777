import styled from 'styled-components';
import CardStyles from '@mui/material/Card';
import CardContentStyles from '@mui/material/CardContent';
import CardHeaderStyles from '@mui/material/CardHeader';
import TypographyStyles from '@mui/material/Typography';
import { Button as ButtonStyles } from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto !important;
`;

export const Content = styled.div`
  display: flex;
`;

export const Card = styled(CardStyles)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  margin-right: 12px;
  width: 330px;
`;

export const CardHeader = styled(CardHeaderStyles)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 20px;

  .MuiTypography-root {
    font-size: 19px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    font-weight: 500;
    line-height: 29px;
  }
`;

export const CardContent = styled(CardContentStyles)`
  padding: 0 20px;
`;

export const TitleNews = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const CreatedAtDate = styled(TypographyStyles)`
  margin-top: 12px;
  font-size: 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[200]};
`;
export const ButtonNews = styled(ButtonStyles)`
  width: 30px;
  height: 30px;
`;
