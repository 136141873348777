import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import { formatDate } from 'utils/formateDate';
import { truncate } from 'utils/truncate';
import { useHistory } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { CardNewsProps } from './props';

import * as Styled from './styles';

const CardNews: React.FC<CardNewsProps> = React.memo(props => {
  const { newsData } = props;
  const history = useHistory();

  const handleRedirectPageNewsDetails = (id: string) => {
    history.push(
      RoutingPath.getRouteString(RoutingPath.NEWS_DETAILS, 'newsId', id),
    );
  };

  return (
    <Styled.Container>
      <Styled.Content>
        {newsData?.map(news => (
          <Styled.Card
            key={news?.id}
            onClick={() => {
              handleRedirectPageNewsDetails(String(news?.id));
            }}
          >
            <Styled.Title
              dangerouslySetInnerHTML={{
                __html: String(truncate(news?.title || '', 50, 54)),
              }}
            />

            <Styled.CardMedia
              component="img"
              image={news?.images[0].thumbnail_256x144}
              alt="Imagem pdf"
            />

            <Styled.CardContent>
              <Styled.ArticleText
                color="tertiary"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: String(truncate(news?.text || '', 200, 154)),
                }}
              />
            </Styled.CardContent>
            <Styled.CardActions>
              <Styled.Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => {
                  handleRedirectPageNewsDetails(String(news?.id));
                }}
              >
                Mais
              </Styled.Button>
            </Styled.CardActions>

            <Styled.Footer>
              <Styled.AuthorText>Autor: {news?.author}</Styled.AuthorText>
              <FaRegClock size={12} color="#9a9a9a" />
              <Styled.DateText>{formatDate(news?.updated)}</Styled.DateText>
            </Styled.Footer>
          </Styled.Card>
        ))}
      </Styled.Content>
    </Styled.Container>
  );
});

export { CardNews };
