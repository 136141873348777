import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  width: 100%;
`;

export const Title = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  padding: 24px 20px 16px 20px;
  font-weight: 700;
`;

export const ConatinerImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const ContentImages = styled.div`
  max-width: 1200px;
  display: inline-block;
`;

export const Images = styled.img`
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;

export const ContentArticle = styled.div`
  padding: 0 72px;

  @media (max-width: 900px) {
    padding: 0 16px;
  }
`;

export const ArticleText = styled(TypographyStyles)`
  text-align: justify;
  color: ${({ theme }) => theme.palette.tertiary.main};

  span {
    color: ${({ theme }) => theme.palette.tertiary.main} !important;
  }

  p {
    margin: 16px 0;
  }

  h1 {
    word-break: break-all;
  }

  strong {
    color: ${({ theme }) => theme.palette.grey[100]};
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 12px 12px 0 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
`;

export const DateText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.grey[200]};
  font-size: 10px;
  margin: 0 10px 0 2px;
`;

export const DescriptionText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;
