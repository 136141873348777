import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './stylesCarousel.css';
import { CarouselProps } from './props';

import * as Styled from './styles';

const Carousel: React.FC<CarouselProps> = React.memo(props => {
  const { imagesSlider } = props;

  return (
    <Styled.Carousel autoPlay infiniteLoop>
      {imagesSlider.map(image => (
        <React.Fragment key={image.url}>
          <Styled.Images src={image.url} alt="Imagem" />
          <p
            style={{
              color: '#FFFFFF',
              textShadow: 'rgb(51 51 51) 1px 1px 2px',
              background: 'none',
              fontSize: '20px',
              fontWeight: '400',
              opacity: '1',
            }}
            className="legend"
          >
            {image.name}
          </p>
        </React.Fragment>
      ))}
    </Styled.Carousel>
  );
});
export { Carousel };
