import React from 'react';
import FaceImage from 'assets/images/face-0.jpg';
import { useResearchers } from 'hooks/researchers';
import { useHistory } from 'react-router-dom';
import { RoutingPath } from 'utils/routing';
import { CardListMembersProps } from './props';

import * as Styled from './styles';

const CardListMembers: React.FC<CardListMembersProps> = React.memo(props => {
  const { userMemberData } = props;
  const { addIndexArrayUsers } = useResearchers();
  const { push } = useHistory();

  const handleGetIndexOfInArrayUsers = (groupsId, userId, user) => {
    const findIndexOfUser = userMemberData.profile.users.indexOf(user);
    addIndexArrayUsers(findIndexOfUser);

    push(
      RoutingPath.getRouteString(
        RoutingPath.OTHER_GROUPS,
        ['groupsId', 'userId'],
        [`${groupsId}`, `${userId}`],
      ),
    );
  };

  return (
    <Styled.Card>
      <Styled.CardHeader title="membros" />

      {userMemberData?.profile?.users.map(user => (
        <Styled.CardContent
          onClick={() =>
            handleGetIndexOfInArrayUsers(user?.groups[0].id, user?.id, user)
          }
        >
          <Styled.CardContainer>
            {user.profile.imagePath ? (
              <Styled.AvatarImage
                src={user.profile.imagePath}
                alt="Imagem membro"
              />
            ) : (
              <Styled.AvatarImage src={FaceImage} alt="Imagem membro" />
            )}
            <Styled.NameMember variant="body1">
              {user.first_name} {user.last_name}
            </Styled.NameMember>
          </Styled.CardContainer>
        </Styled.CardContent>
      ))}
    </Styled.Card>
  );
});

export { CardListMembers };
