import styled from 'styled-components';

import ContainerStyles from '@mui/material/Container';
import TypographyStyles from '@mui/material/Typography';

export const Container = styled(ContainerStyles)`
  display: flex;
  flex-direction: column;
  z-index: 999;
  margin-bottom: 30px;

  @media (min-width: 900px) {
    padding-left: 280px;
  }
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TextYearCiaten = styled(TypographyStyles)``;

export const TextPartners = styled(TypographyStyles)`
  margin-left: 24px;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const Partners = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .fapepi {
    width: 70%;
    height: 70%;
    margin-left: 10px;
  }

  .ufpi {
    width: 20%;
    height: 20%;
  }

  .diadorimi {
    width: 70%;
    height: 70%;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;
