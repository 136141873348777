import styled from 'styled-components';

import CollapseStyles from '@mui/material/Collapse';
import DividerStyles from '@mui/material/Divider';
import ListStyles from '@mui/material/List';
import ListItemIconStyles from '@mui/material/ListItemIcon';
import ListItemTextStyles from '@mui/material/ListItemText';
import IconExpandLessStyles from '@mui/icons-material/ExpandLess';
import IconExpandMoreStyles from '@mui/icons-material/ExpandMore';

interface ListProps {
  component?: string;
}

export const Collapse = styled(CollapseStyles)``;

export const Divider = styled(DividerStyles)``;

export const List = styled(ListStyles)<ListProps>``;

export const ListItemIcon = styled(ListItemIconStyles)`
  color: '#868E96';
`;

export const ListItemText = styled(ListItemTextStyles)``;

export const IconExpandMore = styled(IconExpandMoreStyles)`
  color: #868e96;
  margin-left: 125px;
`;

export const IconExpandLess = styled(IconExpandLessStyles)`
  margin-left: 125px;
  color: #868e96;
`;
