import React from 'react';
import { ResearchersContextData } from 'contexts/researchers/props';
import {
  ResearchersContext,
  initialValue,
  ResearchersProvider,
} from 'contexts/researchers';

export function useResearchers(): ResearchersContextData {
  const context = React.useContext(ResearchersContext);

  if (!context || context === initialValue) {
    throw new Error('usResearchers must be used within a ResearchersProvider');
  }

  return context;
}

export { ResearchersProvider };
