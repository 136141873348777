import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';

export const Container = styled.div``;

export const Title = styled(TypographyStyles)`
  margin: 16px 0;
  font-size: 20px;
`;

export const InformativeText = styled(TypographyStyles)`
  margin-bottom: 16px;
`;

export const Text = styled(TypographyStyles)``;

export const Ol = styled.ol`
  margin-left: 32px;
`;

export const Li = styled.li`
  color: ${({ theme }) => theme.palette.tertiary.main};
`;
