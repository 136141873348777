import { AxiosResponse } from 'axios';
import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { NewsDataProps, NewsUfpiDataProps } from './models/home';

const HomeService = {
  getNews: (): Promise<AxiosResponse<ResponseData<NewsDataProps[]>>> =>
    api.get('website/news/', { params: { limit: 6 } }),
  getNewsUfpi: (): Promise<AxiosResponse<NewsUfpiDataProps[]>> =>
    api.get('website/rss/'),
};

export { HomeService };
