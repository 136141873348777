import React from 'react';

import { Layout } from 'components/Layout';

import { NewsletterCard } from '../components/NewsletterCard';
import { NewsletterDataProps } from '../services/models/newsletters';
import { NewsLettersService } from '../services/newsletters.service';

import * as Styled from './styles';

const CurrentNewsletters: React.FC = React.memo(() => {
  const newsletter = 'Boletins Epidemiológicos';
  const search = 'last';

  const [newsletters, newslettersData] =
    React.useState<NewsletterDataProps[]>();

  React.useEffect(() => {
    NewsLettersService.getNewsletters(search, newsletter).then(({ data }) => {
      newslettersData(data.results);
    });
  }, []);

  return (
    <Layout
      titleHeader="boletim do observatório epidemiológico"
      containerStyles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Styled.Container>
        <NewsletterCard newslettersData={newsletters} title="últimos volumes" />
      </Styled.Container>
    </Layout>
  );
});

export { CurrentNewsletters };
