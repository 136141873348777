import React from 'react';
import { RoutingPath } from 'utils/routing';
import { useHistory } from 'react-router-dom';
import { CardPresentationMemberThematicCoreOrMultidisciplinaryPlatformsProps } from './props';

import * as Styled from './styles';

const CardPresentationMemberThematicCoreOrMultidisciplinaryPlatforms: React.FC<CardPresentationMemberThematicCoreOrMultidisciplinaryPlatformsProps> =
  React.memo(props => {
    const { admin, name, groupId } = props;
    const { push } = useHistory();

    const handleRedirectPageGroups = () => {
      push(
        RoutingPath.getRouteString(
          RoutingPath.OTHER_GROUPS,
          ['groupsId', 'userId'],
          [`${groupId}`, `${admin?.id}`],
        ),
      );
    };

    return (
      <Styled.CardContainer
        onClick={() => {
          handleRedirectPageGroups();
        }}
      >
        <Styled.Card>
          <React.Fragment>
            <Styled.CardHeaderTitle title={name} />
            <Styled.CardHeader
              avatar={
                <Styled.AvatarBorder>
                  <Styled.Avatar
                    src={admin.profile.imagePath}
                    alt="Imagem avatar"
                  />
                </Styled.AvatarBorder>
              }
            />
            <Styled.CardContent>
              <Styled.Title variant="h3">
                {admin.first_name} {admin.last_name}
              </Styled.Title>

              <Styled.EmailText variant="h5">{admin.email}</Styled.EmailText>

              <Styled.DescriptionText variant="body1">
                Coordenador
              </Styled.DescriptionText>

              <Styled.Divider />

              {admin.profile.lattesPath && (
                <a
                  href={admin.profile.lattesPath}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Styled.Lattesext variant="body2">Lattes</Styled.Lattesext>
                </a>
              )}
            </Styled.CardContent>
          </React.Fragment>
        </Styled.Card>
      </Styled.CardContainer>
    );
  });

export { CardPresentationMemberThematicCoreOrMultidisciplinaryPlatforms };
