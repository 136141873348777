import styled from 'styled-components';

import ContainerStyles from '@mui/material/Container';
import TypographyStyles from '@mui/material/Typography';
import { ButtonBase } from '@mui/material';

export const Container = styled(ContainerStyles)`
  display: flex;
  flex-direction: column;
  height: 74px;
  top: 0;
  left: 0;
  padding-left: 290px;
  right: 0;
  z-index: 999;
  min-width: auto;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};

  @media (min-width: 960px) {
    position: initial;
    background-color: ${({ theme }) => theme.palette.background.default};
  }

  @media (max-width: 900px) {
    padding-left: 10px;
  }
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const TitleHeader = styled(TypographyStyles)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-weight: 700;
`;

export const ContentButtonMenuMobile = styled.div`
  display: block;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const ContentButtonLogin = styled.div`
  display: block;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ButtonLogin = styled(ButtonBase)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`;
