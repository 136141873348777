import React from 'react';
import {
  LocalizationContext,
  LocalizationMap,
  ThemeContext,
  ThemeContextProps,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import pt_BR from './pt_BR.json';

import { PDF_VIEWER_WORKER_URL, RenderPDFProps } from './props';

import * as Styled from './styles';

const RenderPDF: React.FC<RenderPDFProps> = React.memo(props => {
  const { src } = props;
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const [currentTheme, setCurrentTheme] = React.useState('light');
  const [l10n, setL10n] = React.useState(pt_BR as unknown as LocalizationMap);

  const localizationContext = React.useMemo(
    () => ({ l10n, setL10n }),
    [l10n, setL10n],
  );

  const themeContext: ThemeContextProps = React.useMemo(
    () => ({ currentTheme, setCurrentTheme }),
    [currentTheme, setCurrentTheme],
  );

  return (
    <Styled.Container>
      <Worker workerUrl={PDF_VIEWER_WORKER_URL}>
        <ThemeContext.Provider value={themeContext}>
          <LocalizationContext.Provider value={localizationContext}>
            <Styled.PDFRenderContainer
              className={`rpv-core__viewer rpv-core__viewer--${currentTheme}`}
            >
              <Styled.PDFToolbar currentTheme={currentTheme}>
                <Toolbar />
              </Styled.PDFToolbar>
              <Styled.PDFViewer>
                <Viewer fileUrl={src} plugins={[toolbarPluginInstance]} />
              </Styled.PDFViewer>
            </Styled.PDFRenderContainer>
          </LocalizationContext.Provider>
        </ThemeContext.Provider>
      </Worker>
    </Styled.Container>
  );
});

export { RenderPDF };
