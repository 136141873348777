import styled from 'styled-components';

import CardStyles from '@mui/material/Card';
import CardActionsStyles from '@mui/material/CardActions';
import CardContentStyles from '@mui/material/CardContent';
import CardMediaStyles, { CardMediaProps } from '@mui/material/CardMedia';
import CardHeaderStyles from '@mui/material/CardHeader';
import TypographyStyles from '@mui/material/Typography';
import GridStyles from '@mui/material/Grid';
import { Button as ButtonStyles } from 'components/Button';

type CardMediaStylesProps = CardMediaProps & {
  component?: string;
  height?: string;
  alt?: string;
};

export const Card = styled(CardStyles)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
`;

export const CardActions = styled(CardActionsStyles)`
  padding: 20px;
`;

export const CardContent = styled(CardContentStyles)``;

export const CardMedia = styled(CardMediaStyles)<CardMediaStylesProps>`
  padding: 20px;
  cursor: pointer;
`;

export const CardHeader = styled(CardHeaderStyles)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .MuiTypography-root {
    font-size: 19px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    font-weight: 500;
    line-height: 29px;
  }
`;

export const ArticleText = styled(TypographyStyles)`
  text-align: justify;
  color: ${({ theme }) => theme.palette.tertiary.main};
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
`;

export const DateText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.grey[200]};
  font-size: 10px;
  margin: 0 10px 0 2px;
`;

export const AuthorText = styled(DateText)`
  margin: 0 16px 0 2px;
`;

export const Grid = styled(GridStyles)`
  margin: 0;

  .MuiGrid-root {
    width: 100%;
  }
`;

export const GridItem = styled(GridStyles)`
  padding: 8px;
`;

export const Button = styled(ButtonStyles)`
  width: 79px;
`;
