import styled from 'styled-components';
import { Carousel as CarouselStyles } from 'react-responsive-carousel';

export const Carousel = styled(CarouselStyles)``;

export const Images = styled.img`
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;
