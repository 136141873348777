/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { FaSearch } from 'react-icons/fa';
import { InputSearchProps } from './props';

import * as Styled from './styles';

const InputSearch = React.forwardRef<HTMLInputElement, InputSearchProps>(
  (props, ref) => {
    const {
      name,
      label,
      onBlur,
      onFocus,
      onChange,
      value,
      type,
      defaultValue,
      disabled,
      id,
      ...rest
    } = props;

    return (
      <Styled.FormControlContainer variant="outlined" fullWidth>
        <Styled.InputContainer
          disabled={disabled}
          ref={ref}
          variant="outlined"
          defaultValue={defaultValue}
          inputRef={ref}
          id={name}
          fullWidth
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          type={type}
          onFocus={onFocus}
          {...rest}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaSearch size={16} color="#9a9a9a" />
              </InputAdornment>
            ),
          }}
        />
      </Styled.FormControlContainer>
    );
  },
);

export { InputSearch };
