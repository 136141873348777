import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';
import TabsStyles from '@mui/material/Tabs';
import TabStyles from '@mui/material/Tab';
import BoxStyles from '@mui/material/Box';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ContentImageAndTexts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const AboutImage = styled.img`
  height: 35%;
  width: 35%;

  @media (max-width: 900px) {
    width: 70%;
    height: 70%;
  }
`;

export const ContentTexts = styled.div`
  margin: 16px 0 0 56px;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const AboutText = styled(TypographyStyles)`
  margin-bottom: 8px;
`;

export const InformativeText = styled.p`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: justify;
`;

export const Tabs = styled(TabsStyles)`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .MuiTabs-indicator {
    display: none;
  }
`;

export const Tab = styled(TabStyles)`
  text-transform: capitalize;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const BoxContainer = styled(BoxStyles)`
  width: 100%;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
`;

export const BoxContent = styled(BoxStyles)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const BoxTabPanel = styled(BoxStyles)``;

export const Typography = styled(TypographyStyles)`
  margin-bottom: 8px;
`;
