import styled from 'styled-components';
import ButtonStyles from '@mui/material/Button';

export const ButtonContainer = styled(ButtonStyles)`
  border-radius: 4px;
  padding: 8px 18px;

  &.MuiButton-containedPrimary:hover {
    background-color: darken(10%, ${({ theme }) => theme.palette.primary.main});
  }

  &.MuiButton-containedSecondary:hover {
    background-color: darken(
      10%,
      ${({ theme }) => theme.palette.tertiary.main}
    );
  }

  svg {
    margin-left: 4px;
  }
`;
