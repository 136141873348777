import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';
import GridStyles from '@mui/material/Grid';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 20px;
  cursor: pointer;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    img {
      margin-bottom: 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const InformativeText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.grey[100]};
  font-weight: 700;
  margin-top: 4px;
`;

export const Grid = styled(GridStyles)`
  margin: 0;

  .MuiGrid-root {
    width: 100%;
  }
`;

export const GridItem = styled(GridStyles)`
  padding: 8px;
`;
