import React from 'react';
import { useHistory } from 'react-router-dom';

import { RoutingPath } from 'utils/routing';
import AboutImage from 'assets/images/about-img.svg';
import { Layout } from 'components/Layout';
import { Loading } from 'components/Loading';

import { NewsletterCard } from '../components/NewsletterCard';
import { NewsletterDataProps } from '../services/models/newsletters';
import { NewsLettersService } from '../services/newsletters.service';

import * as Styled from './styles';

const Newsletters: React.FC = React.memo(() => {
  const newsletter = 'Boletins Epidemiológicos';
  const search = 'last';

  const history = useHistory();
  const [newsletters, newslettersData] =
    React.useState<NewsletterDataProps[]>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    NewsLettersService.getNewsletters(search, newsletter)
      .then(({ data }) => {
        newslettersData(data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleRedirect = (path: string) => {
    history.push(path);
  };

  return (
    <Layout
      titleHeader="boletim do observatório epidemiológico"
      containerStyles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Styled.Container>
        <Styled.PresentationContent>
          <Styled.AboutImage
            src={AboutImage}
            alt="Imagem de pessoas estudando."
          />

          <Styled.RightContent>
            <Styled.ButtonsGroups>
              <Styled.ActionButton
                type="button"
                color="secondary"
                variant="contained"
                onClick={() =>
                  handleRedirect(RoutingPath.NEWSLETTERS_EXPEDIENT)
                }
              >
                expediente
              </Styled.ActionButton>
              <Styled.ActionButton
                type="button"
                color="secondary"
                variant="contained"
                onClick={() =>
                  handleRedirect(RoutingPath.NEWSLETTERS_PUBLISHING_STANDARDS)
                }
              >
                normas de publicação
              </Styled.ActionButton>
              <Styled.ActionButton
                type="button"
                color="secondary"
                variant="contained"
                onClick={() =>
                  handleRedirect(RoutingPath.NEWSLETTERS_PREVIOUS_VOLUMES)
                }
              >
                volumes anteriores
              </Styled.ActionButton>
              <Styled.ActionButton
                type="button"
                color="secondary"
                variant="contained"
                onClick={() =>
                  handleRedirect(RoutingPath.NEWSLETTERS_CURRENT_VOLUME)
                }
              >
                volume atual
              </Styled.ActionButton>
            </Styled.ButtonsGroups>

            <Styled.AboutText variant="h3" color="tertiary">
              Apresentação do periódico
            </Styled.AboutText>

            <Styled.PeriodicPresentation>
              O Boletim do Observatório Epidemiológico (BOE) que o Centro de
              Inteligência em Agravos Tropicais, Emergentes e Negligenciados
              (CIATEN) apresenta aos gestores, trabalhadores e profissionais de
              saúde do Sistema Único de Saúde (SUS), tem como intuito oferecer
              subsídios e informações técnicas, que julgamos indispensáveis à
              definição de prioridades, superação de desafios, campo de
              pesquisas e ao planejamento das ações voltadas para saúde pública
              do Piauí.
            </Styled.PeriodicPresentation>
            <Styled.PeriodicPresentation>
              O acesso ao BOE será livre no formato eletrônico com periodicidade
              trimestral para agravos tropicais, emergentes e negligenciados,
              sendo sua principal missão difundir o conhecimento epidemiológico
              aplicável às ações de vigilância, de prevenção e de controle de
              doenças e agravos de interesse da saúde pública, visando ao
              aprimoramento dos serviços oferecidos pelo Sistema Único de Saúde
              (SUS).
            </Styled.PeriodicPresentation>
          </Styled.RightContent>
        </Styled.PresentationContent>

        <Loading isLoading={loading} color="secondary" />
        <NewsletterCard newslettersData={newsletters} title="último volume" />
      </Styled.Container>
    </Layout>
  );
});

export { Newsletters };
