/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Layout } from 'components/Layout';
import { FaRegClock } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/formateDate';
import { Carousel } from 'components/Carousel';
import { Loading } from 'components/Loading';
import { ProjectsDetailsProps } from './services/models/projects-details';
import { ProjectsDetailsService } from './services/projects-details.service';

import * as Styled from './styles';

const ProjectsDetails: React.FC = React.memo(() => {
  const { projectsId } = useParams<{ projectsId?: string }>();
  const [projectsDetailsData, setProjectsDetailsData] =
    React.useState<ProjectsDetailsProps>({} as ProjectsDetailsProps);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    ProjectsDetailsService.getProjectsDetails(Number(projectsId))
      .then(({ data }) => {
        setProjectsDetailsData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const imagesSlider: Array<{
    url: string;
    name: string;
  }> = projectsDetailsData?.images?.map(image => ({
    url: image?.imagePath,
    name: image?.title,
  }));

  return (
    <Layout titleHeader="notícias sobre o ciaten">
      <Styled.Container>
        <Loading isLoading={loading} color="secondary" />
        <Styled.Title variant="h3">{projectsDetailsData?.title}</Styled.Title>

        <Styled.ConatinerImages>
          <Styled.ContentImages>
            {imagesSlider !== undefined && (
              <Carousel imagesSlider={imagesSlider} />
            )}
          </Styled.ContentImages>
        </Styled.ConatinerImages>

        <Styled.ContentArticle>
          <Styled.DescriptionText>Descrição:</Styled.DescriptionText>
          <Styled.ArticleText
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: String(projectsDetailsData?.description || ''),
            }}
          />
        </Styled.ContentArticle>
        <Styled.Content>
          <FaRegClock size={12} color="#9a9a9a" />
          <Styled.DateText>
            {formatDate(projectsDetailsData?.updated)}
          </Styled.DateText>
        </Styled.Content>
      </Styled.Container>
    </Layout>
  );
});

export { ProjectsDetails };
