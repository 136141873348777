import { AxiosResponse } from 'axios';
import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { NewsDataProps } from './models/news';

const NewsService = {
  getNews: (
    offset: number,
    search?: string,
  ): Promise<AxiosResponse<ResponseData<NewsDataProps[]>>> =>
    api.get('website/news/', { params: { limit: 4, offset, search } }),
};

export { NewsService };
