import { AxiosResponse } from 'axios';
import { api } from 'services/api';
import { ProjectsDetailsProps } from './models/projects-details';

const ProjectsDetailsService = {
  getProjectsDetails: (
    projectsId: number,
  ): Promise<AxiosResponse<ProjectsDetailsProps>> =>
    api.get(`website/projects/${projectsId}/`),
};

export { ProjectsDetailsService };
