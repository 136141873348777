import React from 'react';
import { CardPresentationGroupProps } from './props';

import * as Styled from './styles';

const CardPresentationGroup: React.FC<CardPresentationGroupProps> = React.memo(
  props => {
    const { userMemberData } = props;

    return (
      <Styled.Card>
        <Styled.CardHeader title={userMemberData?.profile?.name || ''} />

        <Styled.CardContent>
          <Styled.DescripitionText variant="body1">
            {userMemberData?.profile?.description}
          </Styled.DescripitionText>
        </Styled.CardContent>
      </Styled.Card>
    );
  },
);

export { CardPresentationGroup };
