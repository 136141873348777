import { AxiosResponse } from 'axios';
import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { InternalRegimeDataProps } from './models/Internal-regime';
import { OverallCoordinationDataProps } from './models/overall-coordination';

const OverallCoordinationService = {
  getOverallCoordination: (): Promise<
    AxiosResponse<ResponseData<OverallCoordinationDataProps[]>>
  > => api.get('groups/', { params: { search: 'Coordenação', limit: 1 } }),
  getInternalRegime: (): Promise<
    AxiosResponse<ResponseData<InternalRegimeDataProps[]>>
  > =>
    api.get('website/documents/', {
      params: { search: 'Documentos Institucionais', limit: 100 },
    }),
};

export { OverallCoordinationService };
