import { AxiosResponse } from 'axios';
import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { ProjectsDataProps } from './models/projects';

const ProjectsService = {
  getPrjects: (
    offset: number,
    search?: string,
  ): Promise<AxiosResponse<ResponseData<ProjectsDataProps[]>>> =>
    api.get('website/projects/', { params: { limit: 10, offset, search } }),
};

export { ProjectsService };
