import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    background-color: #f4f3ef;
    -webkit-font-smoothing: antialiased;

    #app {
      width: 100vw;
      height: 100vh;
    }
  }

  button, a {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;

export { GlobalStyles };
