import React from 'react';
import FaceImage from 'assets/images/face-0.jpg';
import { RoutingPath } from 'utils/routing';
import { useHistory } from 'react-router-dom';
import { OverallCoordinationProps } from './props';

import * as Styled from './styles';

const OverallCoordination: React.FC<OverallCoordinationProps> = React.memo(
  props => {
    const { resultData } = props;
    const { push } = useHistory();

    const handleRedirectPageGroups = () => {
      push(
        RoutingPath.getRouteString(RoutingPath.RESEARCHERS, 'groupsId', '13'),
      );
    };

    return (
      <Styled.Grid container width="100%">
        {resultData.map(item =>
          item.profile.users.map(user => (
            <Styled.GridItem key={user.id} item md={12} lg={6}>
              <Styled.Container onClick={() => handleRedirectPageGroups()}>
                {user.profile.imagePath ? (
                  <img src={user.profile.imagePath} alt="Imagem Profile" />
                ) : (
                  <img src={FaceImage} alt="Imagem Profile" />
                )}

                <Styled.Content>
                  <Styled.InformativeText variant="body1">
                    {user.first_name} {user.last_name}
                  </Styled.InformativeText>
                  <Styled.InformativeText variant="body1">
                    {user.email}
                  </Styled.InformativeText>
                  <Styled.InformativeText variant="body1">
                    {user.profile.description}
                  </Styled.InformativeText>
                </Styled.Content>
              </Styled.Container>
            </Styled.GridItem>
          )),
        )}
      </Styled.Grid>
    );
  },
);

export { OverallCoordination };
