import React from 'react';

import { FaDownload, FaRegClock } from 'react-icons/fa';

import { Button } from 'components/Button';
import { formatDate } from 'utils/formateDate';
import { truncate } from 'utils/truncate';
import { InternalRegimeProps } from './props';
import * as Styled from './styles';

const InternalRegime: React.FC<InternalRegimeProps> = React.memo(props => {
  const { resultInternalRegimeData } = props;

  const showPDF = (url: string) => window.open(url);

  return (
    <Styled.Card>
      {resultInternalRegimeData.map(item => (
        <div key={item.id}>
          <Styled.CardHeader title={item.title} />

          <Styled.CardMedia
            component="img"
            height="100%"
            image={item.thumbnail_256x362}
            alt="Imagem pdf"
          />
          <Styled.CardContent>
            <Styled.ArticleText
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: truncate(item.description, 300, 254),
              }}
            />
          </Styled.CardContent>
          <Styled.CardActions>
            <Button
              Icon={FaDownload}
              type="button"
              color="primary"
              variant="contained"
              onClick={() => showPDF(item.file_path)}
            >
              Download
            </Button>
          </Styled.CardActions>

          <Styled.Content>
            <FaRegClock size={12} color="#9a9a9a" />

            <Styled.DateText>{formatDate(item.updated)}</Styled.DateText>
          </Styled.Content>
        </div>
      ))}
    </Styled.Card>
  );
});

export { InternalRegime };
