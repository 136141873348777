import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1108px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.default};

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  border-radius: 12px;
  width: 100%;

  border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const CardHeader = styled.div`
  width: 100%;
  padding: 10px 32px;
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

export const CardTitle = styled(TypographyStyles)`
  text-transform: uppercase;
  font-weight: 700;
`;

export const CardContent = styled.div`
  padding: 32px;
`;

export const ContentDescription = styled(TypographyStyles)``;

export const Ol = styled.ol`
  margin: 12px 0;
`;

export const Li = styled.li`
  padding: 5px;
  margin-left: 25px;
  color: ${({ theme }) => theme.palette.grey[100]};
`;

export const LiTitle = styled.strong``;

export const Ul = styled.ul`
  margin-top: 24px;

  li {
    padding: 2px;
  }
`;
