import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';
import CardMediaStyles, { CardMediaProps } from '@mui/material/CardMedia';
import GridStyles from '@mui/material/Grid';
import { Button } from 'components/Button';
import { FaClock } from 'react-icons/fa';

type CardMediaStylesProps = CardMediaProps & {
  component?: string;
  height?: string;
  alt?: string;
};

export const Grid = styled(GridStyles)`
  margin-top: 32px;
  border-radius: 12px;

  border: 1px solid rgba(0, 0, 0, 0.125);

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .MuiGrid-root {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  padding: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;

  .MuiGrid-root {
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  max-width: 320px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 12px;

  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.palette.background.default};
  }

  @media (max-width: 900px) {
    min-width: 280px;
    max-width: 100%;
  }
`;

export const CardContent = styled.div`
  width: 100%;

  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.div`
  width: 100%;
  max-height: 111px;
  padding: 10px 32px;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const Title = styled(TypographyStyles)`
  text-align: justify;
  text-transform: uppercase;
`;

export const NewsletterTitle = styled(TypographyStyles)`
  text-align: justify;
`;

export const Description = styled(TypographyStyles)`
  text-align: justify;
  margin: 24px 0;
`;

export const Thumbnail = styled(CardMediaStyles)<CardMediaStylesProps>`
  border-radius: 12px;
`;

export const DownloadButton = styled(Button)``;

export const CitationInfo = styled(TypographyStyles)`
  align-self: flex-end;
`;

export const CreatedAtDate = styled(TypographyStyles)`
  margin-top: 12px;
  font-size: 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const FaClockIcon = styled(FaClock)`
  margin-right: 8px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;
