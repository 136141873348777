import styled from 'styled-components';
import TypographyStyles from '@mui/material/Typography';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px 12px 0 0;

  @media (min-width: 900px) and (max-width: 1280px) {
    width: 50%;
    height: 50%;
  }
`;

export const ContentTexts = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 36px;
  max-width: 600px;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const Title = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`;

export const DescriptionText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin-top: 16px;
  text-align: justify;
`;
