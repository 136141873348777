/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useResearchers } from 'hooks/researchers';
import { CardPresentationMemberProps } from './props';

import * as Styled from './styles';

const CardPresentationMember: React.FC<CardPresentationMemberProps> =
  React.memo(props => {
    const { userMemberData, indexArrayUsers, userId } = props;
    const { user, getUserFind, userFind } = useResearchers();

    React.useEffect(() => {
      if (user) {
        getUserFind(Number(userId));
      }
    }, [user, userId]);

    return (
      <Styled.Card>
        <React.Fragment>
          <Styled.CardHeader
            avatar={
              <Styled.AvatarBorder>
                <Styled.Avatar
                  src={
                    userFind
                      ? userFind?.profile?.imagePath
                      : userMemberData?.profile?.users[indexArrayUsers].profile
                          .imagePath
                  }
                  alt="Imagem avatar"
                />
              </Styled.AvatarBorder>
            }
          />
          <Styled.CardContent>
            <Styled.Title variant="h3">
              {userFind
                ? userFind?.first_name
                : userMemberData?.profile?.users[indexArrayUsers]
                    .first_name}{' '}
              {userFind
                ? userFind?.last_name
                : userMemberData?.profile?.users[indexArrayUsers].last_name}
            </Styled.Title>

            <Styled.EmailText variant="h5">
              {userFind
                ? userFind?.email
                : userMemberData?.profile?.users[indexArrayUsers].email}
            </Styled.EmailText>

            <Styled.DescriptionText variant="body1">
              {userFind
                ? userFind?.profile?.description
                : userMemberData?.profile?.users[indexArrayUsers].profile
                    .description}
            </Styled.DescriptionText>

            <Styled.Divider />

            {userFind ? (
              <a
                href={userFind?.profile?.lattesPath}
                target="_blank"
                rel="noreferrer"
              >
                <Styled.Lattesext variant="body2">Lattes</Styled.Lattesext>
              </a>
            ) : (
              <a
                href={
                  userMemberData?.profile?.users[indexArrayUsers].profile
                    .lattesPath
                }
                target="_blank"
                rel="noreferrer"
              >
                <Styled.Lattesext variant="body2">Lattes</Styled.Lattesext>
              </a>
            )}
          </Styled.CardContent>
        </React.Fragment>
      </Styled.Card>
    );
  });

export { CardPresentationMember };
