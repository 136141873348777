/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Layout } from 'components/Layout';
import { useParams } from 'react-router-dom';
import { withContext } from 'utils/withContext';
import { CardPresentationGroup } from 'components/CardsResearchers/CardPresentationGroup';
import { CardPresentationMember } from 'components/CardsResearchers/CardPresentationMember';
import { CardPresentationWork } from 'components/CardsResearchers/CardPresentationWork';
import { CardListMembers } from 'components/CardsResearchers/CardListMembers';
import { ResearchersDataProps } from 'interface/researchersData';
import { useResearchers, ResearchersProvider } from 'hooks/researchers';
import { CardPresentationBiography } from 'components/CardsResearchers/CardPresentationBiography';
import { OverallCoordinationService } from './services/overall-coordination.service';

import * as Styled from './styles';

export const OverallCoordinationOrTeachingUnit: React.FC = withContext(
  React.memo(() => {
    const { indexArrayUsers } = useResearchers();
    const [
      overallCoordinationOrOverallCoordinationOrTeachingUnitData,
      setOverallCoordinationOrOverallCoordinationOrTeachingUnitData,
    ] = React.useState<ResearchersDataProps>({} as ResearchersDataProps);
    const { groupsId } = useParams<{ groupsId?: string }>();
    const { userId } = useParams<{ userId?: string }>();

    React.useEffect(() => {
      OverallCoordinationService.getOverallCoordination(Number(groupsId)).then(
        ({ data }) => {
          setOverallCoordinationOrOverallCoordinationOrTeachingUnitData(data);
        },
      );
    }, [groupsId]);

    return (
      <Layout
        titleHeader={
          overallCoordinationOrOverallCoordinationOrTeachingUnitData?.profile
            ?.name
        }
      >
        <Styled.Container>
          <Styled.ContentCardLeft>
            <CardPresentationMember
              indexArrayUsers={indexArrayUsers}
              userMemberData={
                overallCoordinationOrOverallCoordinationOrTeachingUnitData
              }
              userId={userId}
            />
            <CardListMembers
              userMemberData={
                overallCoordinationOrOverallCoordinationOrTeachingUnitData
              }
            />
          </Styled.ContentCardLeft>
          <Styled.ContentCardRight>
            <CardPresentationGroup
              userMemberData={
                overallCoordinationOrOverallCoordinationOrTeachingUnitData
              }
            />
            <CardPresentationBiography
              indexArrayUsers={indexArrayUsers}
              userMemberData={
                overallCoordinationOrOverallCoordinationOrTeachingUnitData
              }
              userId={userId}
            />
            {overallCoordinationOrOverallCoordinationOrTeachingUnitData?.profile?.users[
              indexArrayUsers
            ].projects.map(project => (
              <CardPresentationWork
                title={project.title}
                description={project.description}
                financial_source={project.financial_source}
                staff_ciaten={project.staff_ciaten}
              />
            ))}
          </Styled.ContentCardRight>
        </Styled.Container>
      </Layout>
    );
  }),
  ResearchersProvider,
);
