import styled from 'styled-components';

import CardStyles from '@mui/material/Card';
import CardContentStyles from '@mui/material/CardContent';
import CardHeaderStyles from '@mui/material/CardHeader';
import TypographyStyles from '@mui/material/Typography';

export const Card = styled(CardStyles)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  margin-bottom: 24px;
  width: 100%;
`;

export const CardHeader = styled(CardHeaderStyles)`
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .MuiTypography-root {
    font-size: 13px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  }
`;

export const CardContent = styled(CardContentStyles)`
  padding: 20px;
`;

export const DescripitionText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: justify;
`;
