import { AxiosResponse } from 'axios';
import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { ServicesDataProps } from './models/services';

const ServicesService = {
  getServices: (): Promise<AxiosResponse<ResponseData<ServicesDataProps[]>>> =>
    api.get('website/services/categorys/', { params: { limit: 100 } }),
};

export { ServicesService };
