import React from 'react';
import { Layout } from 'components/Layout';
import Pagination from '@mui/material/Pagination';
import { InputSearch } from 'components/Inputs/InputSearch';
import { Loading } from 'components/Loading';
import { CardNews } from './components/CardNews';
import { NewsService } from './services/news.service';
import { NewsDataProps } from './services/models/news';

import * as Styled from './styles';

const LIMIT_PER_PAGE = 4;

const News: React.FC = React.memo(() => {
  const [news, newsData] = React.useState<NewsDataProps[]>();
  const [count, setCount] = React.useState<number | null>(0);
  const [offset, setOffset] = React.useState(0);
  const [search, setsearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (search) {
      setOffset(0);
    }
    setLoading(true);

    NewsService.getNews(offset, search)
      .then(({ data }) => {
        newsData(data.results);
        setCount(Number(data.count) / LIMIT_PER_PAGE);
      })
      .finally(() => setLoading(false));
  }, [offset, search]);

  const handleNextOrPreviousPage = (e, p) => {
    setOffset((p - 1) * LIMIT_PER_PAGE);
  };

  const showContent = () => {
    if (loading) {
      return <Loading isLoading={loading} color="secondary" />;
    }
    return <CardNews newsData={news} />;
  };

  return (
    <Layout titleHeader="notícias sobre o ciaten">
      <Styled.Container>
        <Pagination
          count={Number(count)}
          showFirstButton
          showLastButton
          onChange={handleNextOrPreviousPage}
          color="secondary"
        />
        <Styled.ContentInput>
          <InputSearch
            label=""
            name="search"
            onChange={e => setsearch(e.target.value)}
            value={search}
          />
        </Styled.ContentInput>
      </Styled.Container>
      {news?.length === 0 ? (
        <Styled.ContentNewsNotFound>
          <Styled.NewsNotFoundText variant="h3">
            Nenhuma notícia encontrada
          </Styled.NewsNotFoundText>
        </Styled.ContentNewsNotFound>
      ) : (
        showContent()
      )}
    </Layout>
  );
});

export { News };
