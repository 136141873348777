import styled from 'styled-components';

import CardStyles from '@mui/material/Card';
import CardContentStyles from '@mui/material/CardContent';
import TypographyStyles from '@mui/material/Typography';

export const Card = styled(CardStyles)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 12px;
  margin-bottom: 24px;
  width: 100%;
`;

export const CardContent = styled(CardContentStyles)`
  padding: 20px;
`;

export const DescripitionText = styled(TypographyStyles)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: justify;

  span {
    color: ${({ theme }) => theme.palette.tertiary.main} !important;
  }
`;

export const CardHeaderWithImage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 12px 12px 0 0;
`;

export const TitleCardHeraderWithImage = styled(TypographyStyles)`
  font-size: 13px;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;

  max-width: 350px;
`;

export const ContentImages = styled.div`
  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const HeaderImages = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0 8px 8px;
  cursor: pointer;
`;

export const FinancialSourceText = styled(TypographyStyles)`
  margin-top: 24px;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`;
