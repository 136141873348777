import { AxiosResponse } from 'axios';

import { ResponseData } from 'interface/responseData';
import { api } from 'services/api';
import { NewsletterDataProps } from './models/newsletters';

const NewsLettersService = {
  getNewsletters: (
    newsletter: string,
    search: string,
    offset?: number,
  ): Promise<AxiosResponse<ResponseData<NewsletterDataProps[]>>> =>
    api.get('/website/documents/', {
      params: { limit: 100, offset, search, newsletter },
    }),
  getNewsletter: (
    newsletterId: string,
  ): Promise<AxiosResponse<NewsletterDataProps>> =>
    api.get(`/website/documents/${newsletterId}/`),
};

export { NewsLettersService };
