import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentCardLeft = styled.div``;

export const ContentCardRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
